.input {
  border-top: 1px solid aquamarine;
  padding-top: 24px;
  margin-bottom: 24px;
}

.input__form::placeholder,
.input__subForm::placeholder {
  color: #dde2e78c;
}

.input__form,
.input__subForm,
.input__dateForm {
  transition: all 250ms;
  background: none;
  color: aliceblue;
  border: none;
  border-bottom: 1px solid #e5ecf355;
  outline: none;
}

.input__form {
  width: 100%;
}

.input__subForm {
  width: 50%;
}

.input__form:focus,
.input__subForm:focus,
.input__dateForm:focus {
  border-bottom: 1px solid #e5ecf3;
}
.input__dateForm::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}

.input__toolButtons > * {
  display: inline;
}

.input__singleTool {
  display: block;
  margin-top: 20px;
}

.input__toolButtons > * {
  transition: all 240ms;
  margin: 12px 4px;
  border: 1px solid #7fffd4;
  background: none;
  color: #e5ecf3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.input__toolButtons > *:focus {
  outline: none;
}

.input__toolButtons > *:hover {
  background-color: #7fffd4;
  color: black;
}

.input__toolButtons > *:disabled {
  border: 1px solid #ffc37f75;
  cursor: not-allowed;
}

.input__toolButtons > *:disabled:hover {
  background: none;
  color: black;
}

.textArea {
  transition: border 200ms;
  padding-left: 12px;
  width: 100%;
  min-height: 100px;
  background: none;
  border-radius: 12px;
  border: 1px solid #e5ecf355;
  color: aliceblue;
}

.textArea:focus {
  border: 1px solid #e5ecf3;
  outline: none;
}
