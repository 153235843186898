body{
  color: black;
}



.form {
  margin-bottom: 42px;
  
}

.form__mainWithTool {
  margin-bottom: 12px !important;
}

.form__tool {
  margin-bottom: 24px;
}

.form__moveUp {
  transform: translateY(-36px);
}

.form__buttons {
  display: flex;
  flex-wrap: wrap;
}

.form__button {
  transition: all 200ms;
  background: none;
  border: 1px solid #7fffd4;
  color: #7fffd4;
  margin-right: 12px;
}

.form__button:hover {
  background-color: #7fffd4;
  color: black;
}

.form__button:focus {
  outline: none;
}

.form__dates {
  display: flex;
}

.form__dates > * {
  margin-right: 30px;
  margin-bottom: 30px;
}

.form__noDisplay {
  display: none;
}

.input {
  border-top: 1px solid aquamarine;
  padding-top: 24px;
  margin-bottom: 24px;
}

.input__form::placeholder,
.input__subForm::placeholder,
.input__profileForm::placeholder {
  color: darkslategray;
}

.input__form,
.input__subForm,
.input__profileForm {
  transition: all 250ms;
  background: none;
  color: black;
  border: none;
  border-bottom: 1px solid #e5ecf355;
  outline: none;
}

.input__form {
  width: 100%;
}

.input__subForm,
.input__profileForm {
  width: 50%;
}

.input__form:focus,
.input__subForm:focus,
.input__profileForm:focus {
  border-bottom: 1px solid #e5ecf3;
}

.input__selectForm::placeholder {
  color: darkslategray;
}

.input__selectForm {
  transition: all 250ms;
  background: none;
  color: darkslategray;
  border: none;
  border-bottom: 1px solid #e5ecf355;
  outline: none;
  width: 50%;
}

.selectForm:focus {
  border-bottom: 1px solid #e5ecf3;
}

.form__loader {
  z-index: 10;
  background-color: #ffffff00;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  animation: loaderBGFadeIn 200ms linear forwards;
}

.form__spinner {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-top: 5px solid white;
  position: relative;
  top: calc(50% - 50px);
  left: 50%;
  animation: spinner 500ms linear infinite;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.projectForm > form > .input > select > option {
  color: black;
}

@media (max-width: 500px) {
  .form__buttons,
  .form__dates {
    flex-direction: column;
  }
  .form__button {
    margin-bottom: 12px;
  }
  .input__subForm {
    width: 75%;
  }
  .input__selectForm,
  .input__profileForm {
    width: 100%;
  }
}