.announcement {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px; 
}

.announcementText {
    color: white;
    font-size: 15px; 
}

.announcement-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px; 
}

.announcementText-mobile {
    color: white;
    font-size: 11.5px; 
    width: 90%;
}