.navItem {
  margin: 4px 11px;
  font-size: 20px;
  transition: all 200ms;
}

.navItem.home {
  color: black;
}

.navItem.other {
  color: white;
}

.navItem:hover {
  cursor: pointer; /* Cursor change to indicate interactivity */
}

@media (max-width: 950px) {
  .navItem__noDisplaySmall {
    display: none;
  }
}
