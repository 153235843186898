.interview-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3rem; /* Adjust for desired size */
    font-weight: bold;
    color: #2E2E2E; /* Adjust for desired color */
}
  
.interview-title .timer {
    font-size: 1.25rem; /* Adjust for desired size */
    color: #6D6D6D; /* Adjust for desired color */
    margin-top: 10px; /* Space between title and timer */
    display: flex;
    align-items: center;
}

.nora-form-2 {
    display: flex;
    justify-content: space-between;
    height: 82vh;
    box-sizing: border-box;
    position: relative;
    padding-left: 12.5%;
    padding-bottom: 30px;
    padding-right: 12.5%;
    padding-top: 20px;
    margin-top: 20px;
}

.controls-section-2 {
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #ccc9c9;
    position: relative;
}

.endInterviewOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.endInterviewContent {
    background: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.endInterviewContent h2 {
    font-size: 16px; 
    font-weight: 600; 
    margin-bottom: 15px;
}

.modal-buttons {
    text-align: center; 
    justify-content: space-between;
    margin-top: 20px;
}

.modal-buttons button {
    margin: 0 10px; 
    font-size: 14px;
}

.modal-confirm-button, .modal-cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.modal-confirm-button {
    background-color: #ff4d4d;
    color: white;
}

.modal-cancel-button {
    background-color: #4caf50;
    color: white;
}

.modal-confirm-button:hover {
    background-color: #ff1a1a;
}

.modal-cancel-button:hover {
    background-color: #45a049;
}

.nora-zoom.nora-speaking {
    border: 4px solid #4CAF50;
    animation: borderBlink 1s infinite;
    border-radius: 11px;
}

@keyframes borderBlink {
    0% { border-color: #4CAF50; }
    50% { border-color: transparent; }
    100% { border-color: #4CAF50; }
}

.video-container-2 {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.video-wrapper-2 {
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 40%;
    bottom: -60px;
}

.nora-zoom {
    max-width: 75%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video2-feed {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scaleX(-1);
}

.video2-feed.hidden {
  display: none;
}

.endInterviewBtn2 {
    padding: 10px 0; 
    background-color: #213546; /* Match the background color from the screenshot */
    color: white; /* White text */
    border: none;
    border-radius: 10px; /* Rounded button */
    cursor: pointer;
    width: 24%; /* Cover 90% of the parent container */
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    position: fixed;
    bottom: 11%;
}

.controls2 {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    position: absolute;
    bottom: 10px;
    width: 100%;
  }

.audio-visualizer2 {
    position: absolute;
    bottom: 10px;
    right: 10%;
    z-index: 10;
}

.mic-styling2 {
    margin-bottom: 5px;
    margin-left: 4%;
    width: 63px; 
    height: 35px;
    border-radius: 20px;
}

.instructionsImage2 {
    padding: 30px;
    margin-top: 20px;
    text-align: center;
}

.instruction-container2 h1 {
    font-size: 30px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5%;
}

.instruction-container2 p {
    font-size: 16px;
    font-weight: 400;
    color: #555;
    margin-bottom: 6%;
    line-height: 1.5;
    width: 80%;
    margin-left: 10%;
}

.instruction-checklist2 p {
    font-size: 17px;
    font-weight: 400;
    color: #333;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 18%;
}

.demo-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    font-size: 16px;
    gap: 5px; 
    margin-bottom: 4%;
}

.demo-link-anchor {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    margin-bottom: 10px;
}

.demo-link-anchor:hover {
    color: #0056b3;
}

.youtube-icon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
}

.checkmark {
    color: #4caf50;
    font-size: 20px;
    margin-right: 8px;
}

.video-status {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-color: #ff5252;
    border-radius: 5px;
    padding: 4px 8px;
    margin-left: 5px;
}

.start-button2 {
    margin-top: 40px;
    padding: 10px 40px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #213546;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.video-status-button {
    color: #fff;
    border: none;
    border-radius: 12px;
    padding: 4px 12px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    margin-left: 4px;
}

/* Red button for "Video Off" state */
.video-status-button.video-off {
    background-color: #FF5C5C; /* Red color */
}

/* Green button for "Video On" state */
.video-status-button.video-on {
    background-color: #4CAF50; /* Green color */
}

/* Hover effects for better interactivity */
.video-status-button.video-off:hover {
    background-color: #FF3A3A;
}

.video-status-button.video-on:hover {
    background-color: #45A049;
}

.completionScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.completion-content {
    text-align: center;
    padding: 30px;
    width: 100%;
}

.completion-icon {
    width: 180px;
    height: 180px;
    margin-bottom: 35px;
}

.completionScreen h2 {
    font-size: 30px;
    font-weight: bold;
    color: #3A3A3A;
    margin-bottom: 30px;
}

.completionScreen p {
    font-size: 18px;
    color: #4F4F4F;
    line-height: 1.5;
}

@media only screen and (max-width: 1500px) and (min-width: 900px) {
    .video-wrapper-2 {
      max-width: 50%;
    }

    .audio-visualizer2 {
        right: 6%;
      }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
    .nora-form-2 {
      flex-direction: column;
    }

    .mic-styling2 {
        width: 42px;
        height: 24px;
    }
}

@media only screen and (max-width: 600px) {
    .nora-form-2 {
      flex-direction: column;
    }

    .mic-styling2 {
        width: 28px;
        height: 16px;
    }

    .instructionsImage2 {
        padding: 0px;
    }

    .demo-link {
        display: block;
        margin-top: 10%;
        margin-bottom: 12%;
    }

    .demo-link-anchor {
        display: block;
    }

    .instruction-checklist2 p {
        margin-left: 0%;
    }
}