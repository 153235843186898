html {
  font-size: 1.5vh;
}
body {
  font-family: Montserrat;
}
.highlight {
  height: fit-content;
  color: black;
  padding: 20px 0 20px;
}

.background {
  height: 25vh;
  width: 90%;
  margin: -26.5vh auto 0;
  box-shadow: inset 0 0 1000px rgba(255, 255, 255, 0.753);
  border-radius: 10px;
  z-index: 0;
  border: 1px solid white;
  transition: 0.5s;
}
.bg-img{
  display: flex;
  flex-direction: column;
  background-size: cover;
  padding: 2rem 9vw 4rem 2vw;
  height: 100vh;
  width: 100vw;
}
.grid-container:hover {
  cursor: pointer;
}

#errorBanner
{
  text-align:start;
  display: none;
  background-color: lightcoral;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}

#errorBannerSave
{
  text-align:start;
  display: none;
  background-color: lightcoral;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}

#savedBanner
{
  text-align:start;
  display: none;
  background-color: #5ea749;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.grid-container {
  position: static;
  display: grid;
  max-width: none;
  width: 90%;
  height: 23.5vh;
  grid-template-columns: 1fr 3fr 2fr 2fr;
  margin: 15px auto;
}

.grid-container > * {
  z-index: 1;
  overflow-y: auto;
}

.grid-container h2 {
  font-size: 2rem;
}

.navbar {
  background-color: #203546;
}

.company-logo {
  margin: 0 auto 35px;
  align-self: center;
}

.section1 {
  align-self: center;
}

.section1 p {
  margin: 4% 0 0;
  color: #1daf5c;
}

.section2 p {
  display: inline;
  margin-left: 10px;
}

.section2 h5
{
  margin-left:500px;
}
.section3 h5 {
  margin-bottom: 12.5px;
}

.qualification-item {
  display: block;
  margin: 0 0 5px 20px;
}

.section3 li {
  z-index: 1;
  margin-bottom: 5px;
}

.section3 {
  position: static !important;
  z-index: 0;
  width: 70%;
  overflow: hidden;
}

.topmatches {
  height: fit-content;
  color: black;
  padding: 10px 0 0 0;
}

.grid {
  display: grid;
  max-width: none;
  width: 90%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 50px 183;
  grid-auto-rows: 183;
  margin: 0 auto;
}

.grid .header {
  grid-column-start: 1;
  grid-column-end: 4;
  color: #203546;
  padding-left: 15px;
  align-content: space-between;
}

.seemore {
  grid-column: 4/5;
  grid-row: 1/2;
  color: #203546;
  justify-self: right;
  text-decoration: underline;
}

.seemore:hover {
  cursor: pointer;
}

.placard {
  display: grid;
  background-color: white;
  padding: 20px 20px 10px 20px;
  margin: 15px;
  border-radius: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 2fr 1fr 1fr 1fr 2fr;
  height: 22vh;
}

.placard button {
  border: 1px solid #035da9;
  border-radius: 10px;
  height: 70%;
  align-self: center;
}
.placard p,
h5,
h6 {
  margin-bottom: 0;
}
.placard p {
  font-size: smaller;
}
.placard-logo {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  align-self: center;
}
.placard-position {
  grid-column: 2 / 4;
  grid-row: 1 / 2;
  align-self: center;
}
.placard-percentage {
  grid-column: 4 / 5;
  grid-row: 1 / 2;
  justify-self: end;
  color: #1daf5c;
}
.placard-company {
  grid-column: 2 / 4;
  grid-row: 2 / 3;
}
.placard-location {
  grid-column: 2 / 4;
  grid-row: 3 / 4;
}
.placard-applicants {
  grid-column: 4 / 5;
  grid-row: 3 / 4;
}

.placard-date {
  grid-column: 1 / 3;
  grid-row: 4 / 5;
}
.placard-expire {
  grid-column: 3 / 5;
  grid-row: 4 / 5;
  justify-self: end;
  font-weight: bold;
}
.placard-savebtn {
  grid-column: 1 / 3;
  grid-row: 5 / 6;
  background-color: white;
  color: #035da9;
  margin-right: 5px;
}
.placard-applybtn {
  grid-column: 3 / 5;
  grid-row: 5 / 6;
  background-color: #035da9;
  color: white;
  margin-left: 5px;
}
.placard-alt-header {
  grid-column: 1/5;
  grid-row: 1/2;
  font-weight: bold;
}
.similarmatches {
  height: fit-content;
  color: black;
  padding: 20px 0 50px;
}
.placard-tag {
  border: 1px solid #1daf5c;
  border-image-slice: 1;
  background-color: white;
  color: #1daf5c;
  text-align: center;
  border-radius: 10px;
  margin: 0 10px 10px 0;
  padding: 0 7.5px;
  display: inline-block;
}
.placard-alt-tag-container {
  grid-column: 1/5;
  grid-row: 2/5;
  overflow-y: auto;
}
.border-gradient-purple {
  border-image-source: linear-gradient(to left, #b78efd, #d671b1);
}

.modal-wrapper {
  height: fit-content;
  color: rgb(0, 0, 0);
  padding: 60px 100px 20px;
}

.modal-grid {
  display: grid;
  max-width: none;
  width: 100%;
  height: 80%;
  grid-template-columns: 1fr 3fr 5fr;
}

.modal-company-logo {
  justify-self: end;
  margin-right: 30px;
}
.modal-section1 {
  grid-column: 2/4;
  grid-row: 1/2;
}
.modal-section1 p {
  margin: 30px 0 0 0;
  color: rgb(8, 223, 8);
}

.modal-section5 {
  grid-column: 2/4;
  grid-row: 2/4;
}
.modal-section5 .internship {
  padding-right: 6.4vw;
}
.modal-section5 .job-type, .location, .hours, .pay{
  padding-right: 7vw;
}
.modal-section5 .remote {
  padding-right: 7.2vw;
}
.modal-section5 .in-person {
  padding-right: 6.5vw;
}
.modal-section5 .full-time {
  padding-right: 5.7vw;
}
.modal-section5 .part-time {
  padding-right: 5.4vw;
}


.Match{
  margin-top: 80px;
  grid-column: 2/4;
  grid-row: 2/4;
  font-size: 20px;
  color: rgb(8, 223, 8);
}

.modal-section2 {
  grid-column: 2/3;
  grid-row: 3/3;
  margin-top: 120px;
  overflow: hidden;

}

.modal-section2 p {
  display: inline;
  margin-left: 10px;
  /* font-weight: bolder; */
}
.modal-section2 h5 {
  margin-bottom: 12.5px;
  font-size: 23px;
}
.modal-section3 h5 {
  margin-bottom: 12.5px;
  font-size: 23px;
}
.modal-section3 {
  grid-column: 3/4;
  grid-row: 3/3;
  margin-top: 120px;
  margin-bottom: -25px;
}
.modal-section3 li {
  margin-bottom: 5px;
}

.modal-section3 ul {
  margin-left: -10px;
}
.modal-section4 {
  grid-column: 3/4;
  grid-row: 1/2;
  justify-self: end;
  align-self: end;
}
.modal-section4 p {
  margin-bottom: 0;
}
.about h4 {
  margin-bottom: 15px;
}

.about {
  display: flex;
  align-items: center;
}

.about img {
  margin-right: 0.7vw; 
}

.about p {
  margin-right: 4vw; 
  margin-top: 10px;
}

.socials {
  margin-top: 20px;
}

.socials img {
  margin-right: 0.5vw;
}

.description {
  margin-top: 50px;
  margin-bottom: 30px;
}
.description h4 {
  margin-bottom: 10px;
}
.buttons {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.buttons button {
  border: 1px solid #035da9;
  border-radius: 10px;
  align-self: center;
  width: 45%;
  height: 50px;
}
.exit {
  position: absolute;
  right: 60px;
  top: 50px;
  background-color: white;
  border: 0px;
  font-size: 2rem;
}

.chance-item {
  display: block;
  margin: 0 0 5px;
}
.chance-item p {
  display: inline;
  margin-left: 10px;
}
.add-btn {
  margin-top: 1rem;
  padding: 0.5rem;
}

@media (max-width: 1200px) {
  .placard-position {
    font-size: 20px;
  }
  .placard-company {
    font-size: 18px;
  }
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .grid .header {
    grid-column: 1/3;
  }
  .seemore {
    grid-column: 3/4;
  }
  .apply-step {
    font-size: 1rem;
  }
  .modal-wrapper {
    padding: 30px 30px 20px;
  }
  .modal-company-logo {
    margin-right: 10px;
    justify-self: left;
  }
  .modal-section1 h2{
    font-size: 25px;
  }
  .modal-section1 h4{
    font-size: 23px;
  }
  .modal-section1 h5{
    font-size: 21px;
  }
  .Match {
    grid-column: 1/4;
    grid-row: 2/4;
    font-size: 19px;
    margin-top: 8vh;
  }
  .modal-section2 h5{
    font-size: 20px;
  }
  .modal-section2 {
    grid-column: 2/4;
    grid-row: 3/4;
    margin-top: 12vh;  
    margin-left: -120px;
    justify-self: left;
  }
  .modal-section3 {
    margin-top: 12vh;  
  }
  .modal-section3 h5{
    font-size: 20px;
  }

  .modal-section4 {
    grid-row: 1/3;
    justify-self: right;
    font-size: 20px;
  }
  
  .modal-section5 {
    grid-column: 1/4;
    grid-row: 2/4;
    margin-top: 3vh;
  }
  .modal-section5 h5{
    font-size: 23px;
  }
  .modal-section5 .internship, .job-type{
    position: absolute;
  }
  .modal-section5 .in-person, .remote {
    position: absolute;
    padding-left: 25%;
  }
  .modal-section5 .location {
    position: absolute;
    padding-left: 25%;
  }
  .modal-section5 .part-time, .full-time{
    position: absolute;
    padding-left: 50%;
  }
  .modal-section5 .hours {
    position: absolute;
    padding-left: 50%;
  }
  .modal-section5 .pay{
    margin-left: 70vw;
  }
  .modal-section5 .paid, .unpaid
  {
    position: absolute;
    padding-left: 70vw;
  }
}

@media (max-width: 770px) {
  .placard-position {
    font-size: 18px;
  }
  .placard-company {
    font-size: 16px;
  }
  .grid {
    grid-template-columns: 1fr 1fr;
  }
  .grid .header {
    grid-column: 1/2;
  }
  .seemore {
    grid-column: 2/3;
  }
  .grid-container {
    grid-template-columns: 1fr 2fr;
    align-items: center;
  }
  .company-logo {
    margin: 0 auto;
    align-self: center;
  }

  .modal-wrapper {
    padding: 12px 0px 20px;
  }

  .modal-grid {
    display: grid;
    max-width: none;
    width: 100%;
    height: 80%;
    grid-template-columns: 1fr 3fr;
  }

  .modal-company-logo {
    width: 80px;
    height: 80px;
    font-size: 100px;
    margin-right: 10px;
    justify-self: left;
  }
  .modal-section1 h2{
    font-size: 17px;
  }
  .modal-section1 h4{
    font-size: 14px;
  }
  .modal-section1 h5{
    font-size: 12px;
  }
  .modal-section2 {
    grid-column: 2/2;
    grid-row: 3/4;
    margin-top: 100px;
    margin-left: -22.5vw;
    justify-self: left;
  }
  .modal-section2 h5{
    font-size: 17px;
  }
  .modal-section2 img{
    margin-left: -18px;
  }
  .modal-section3 {
    grid-column: 1/3;
    grid-row: 4/4;
    margin-top: 40px;
    justify-self: center;
  }
  .modal-section3 h5{
    font-size: 17px;
  }
  .modal-section4 {
    grid-column: 2/3;
    grid-row: 1/3;
    justify-self: right;
    margin-top: 70px;
    font-size: 12px;
  }
  .modal-section5 {
    grid-column: 1/4;
    grid-row: 2/4;
    margin-top: 20px;
  }
  .modal-section5 h5{
    font-size: 14px;
  }
  .modal-section5, .job-type, .location, .hours, .pay {
    margin-right: 2.3vw;
  }

  .description h4{
    font-size: 17px;
  }

  .Match {
      grid-column: 1/4;
      grid-row: 3/5;
      font-size: 14px;
      margin-top: 70px;
  }

  .apply-container {
    width: 100%;
  }

  .apply-progress {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .apply-progress-line {
    width: 4rem;
  }
  .apply-progress-circle {
    width: 2%;
  }
}

@media (max-width: 500px) {
  .grid {
    grid-template-columns: 3fr 1fr;
  }
  .placard {
    grid-column: 1/3;
  }
  .seemore {
    justify-self: center;
  }
}

.searchbar-container {
  position: static;
  max-width: none;
  width: 90%;
  margin: 15px auto;
}
.searchbar {
  margin-left: auto;
  border-radius: 25px;
  margin-right: 20px;
  width: 40%;
  min-width: 300px;
  height: 44px;
  padding-left: 20px;
}
.searchbar:focus {
  outline: none;
}
.filter {
  cursor: pointer;
}

.filter-modal h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  font-size: 40px;
  line-height: 49px;
}

.filter-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.filter-grid h4 {
  margin: 20px 0 10px 0;
}
.multiple-check label {
  margin-left: 15px;
}
.grid1,
.grid2 {
  display: flex;
  flex-direction: column;
}
.multiple-check input {
  transform: scale(1.3);
  margin-left: 25px;
}
.manual input {
  border: 1px solid black;
  border-radius: 25px;
  width: 90%;
  height: 40px;
  padding-left: 20px;
}
.manual input:focus {
  outline: none;
}

.page1 {
  color: black;
  margin-right: 10px;
}
.loader3 {
  position: absolute;
  z-index: 100;
  top: 0;
  display: flex;
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(6px);
  text-align: center;
  justify-content: center;
  align-items: center;
}
.loader3::-webkit-scrollbar {
  display: none;
}

.loader2 {
  border: 8px solid #f3f3f300;
  border-radius: 50%;
  border-top: 8px solid #4fc984;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; 
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.apply-wrapper {
  color: black;
  display: flex;
  flex-direction: column;
}
.apply-header {
  display: flex;
  border-bottom: 1px solid rgb(167, 167, 167);
  padding: 15px 0;
}

.apply-progress {
  width: 100%;
  background-color: white;
  height: fit-content;
  align-self: center;
  margin: 50px 0 0 0;
  display: flex;
  justify-items: center;
  align-items: center;
}
.apply-progress-circle {
  background-color: white;
  border: 1px solid #213546;
  border-radius: 291px;
  width: 0.9vw;
  height: 0.9vw;
  margin: 0 auto;
}
.apply-progress-line {
  background-color: #213546;
  width: 9.5vw;
  height: 1px;
  justify-self: center;
  align-self: flex-start;
  margin: 7px 1px 0;
}
.apply-progress > span {
  width: 3vw;
  text-align: center;
  align-self: flex-start;
}
.apply-step {
  margin-top: 15px;
  font-size: 0.8rem;
}
.apply-progress-active > div {
  background: #8ec7a6;
  border: 2px solid #1daf5c;
}
.apply-progress-active > p {
  color: #1daf5c;
}

.apply-btn-wrapper {
  align-self: flex-end;
  max-width: 40%;
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  margin-right: 1rem;
  margin-left: auto;
}
.apply-btn-wrapper .backbtn {
  width: 10vw;
  height: 3vw;
  min-width: 113px;
  min-height: 34px;
  background: white;
  border-radius: 8px;
  border-color: #035da9;
  color: #035da9;
  font-family: Montserrat;
  font-style: normal;
  font-size: 24px;
  line-height: 29px;
  margin-right: 1.5rem;
}
.apply-btn-wrapper .nextbtn {
  width: 10vw;
  height: 3vw;
  min-width: 113px;
  min-height: 34px;
  background: #035da9;
  border-radius: 8px;
  color: white;
  font-family: Montserrat;
  font-style: normal;
  font-size: 24px;
  line-height: 29px;
}
.add-btn {
  margin-top: 2rem !important;
  color: rgb(26, 180, 129) !important;
}
.css-yk16xz-control,
#upload {
  background-color: rgb(231, 231, 231) !important;
  cursor: pointer;
  display: block;
  width: 30vw;
}
.css-tj5bde-Svg {
  color: black;
  cursor: pointer;
}

.select-accordion {
  width: 60%;
  margin-top: 1rem;
}

.MuiSlider-thumb {
  width: 18px !important;
  height: 18px !important;
  margin-top: -7px !important;
}
.MuiSlider-root {
  max-width: 40%;
}
.MuiSlider-track {
  height: 6px !important;
  border-radius: 16px !important;
}
.align-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.MuiDialog-paperWidthSm {
  position: absolute;
  left: 1rem;
}
@media only screen and (max-width: 650px) {
  .apply-progress {
    align-items: center;
  }
  .apply-progress-line {
    width: 3.5rem;
  }
  .apply-progress-circle {
    width: 1rem;
    height: 1rem;
  }
  .apply-step {
    font-size: 0.7rem;
    width: 3.5rem;
    text-align: center;
    margin: 6px 2px;
    margin-right: 2px;
    overflow: hidden;
    margin-left: -8px;
  }
  .apply-btn-wrapper {
    max-width: 70%;
    justify-content: space-around;
    margin-right: 0;
  }
  .exit {
    right: 1rem;
    font-size: 1.8rem;
    top: 0;
  }
  .apply-btn-wrapper .backbtn {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.9rem;
  }
  .apply-btn-wrapper .nextbtn {
    max-width: 1rem;
    height: 1rem;
    font-size: 0.9rem;
  }
  .select-accordion {
    width: 80%;
    margin-top: 1rem;
  }
  #myrange {
    min-height: 0.5rem;
    padding: 1rem;
  }
  .MuiSlider-root {
    max-width: 80%;
  }
  .apply-wrapper {
    height: 100%;
  }
  .apply-page-long {
    min-height: 80%;
  }
  form {
    height: 100%;
  }
}
.apply-page-long h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 2vw;
  line-height: 1.8vw;
  margin-top: 1rem;
}
.apply-page-long h3 {
  font-size: 1.6vw;
}
.apply-page-long h4 {
  font-size: 1.1vw;
}

.apply-page-long p {
  font-style: normal;
  font-size: 1vw;
  line-height: 1.5vw;
  margin-top: 1rem;
}
.apply-page-long label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1vw;
  margin-top: 1rem;
  display: block;
}
.apply-wrapper form {
  margin-left: 1rem;
}
.apply-page-long input {
  display: block;
  background: #f7f7f7;
  border: 1px solid #c4c4c4;

  box-sizing: border-box;
  border-radius: 2px;
  width: 60%;
  height: 3.5vw;
  padding-left: 1%;
  cursor: pointer;
}
.apply-page-long .input-radio {
  display: inline;
  width: 16px;
  height: 16px;

  border-radius: 5px;
  margin: 0 10px 0 10px;
}
.file-wrapper {
  display: block;
  background: #f7f7f7;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  width: 50%;
  min-height: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.apply-page-long input[type="file"] {
  border: none;
}
.input-radio {
  width: 5% !important;
}
.radio-label {
  margin-bottom: 10px;
  margin-top: 0;
}
.apply-page-long select {
  width: 25vw;
  height: 3vw;
  display: block;
  background: #f7f7f7;
  border-radius: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}

.apply-page-long textarea {
  display: block;
  background: #f7f7f7;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  width: 50%;
  min-height: 100px;
}
.apply-page-long .date-wrapper {
  width: 17%;
  margin-right: 2%;
  display: inline-block;
}
.apply-page-long .input-date {
  width: 100%;
}
.apply-deletebtn {
  width: 12%;
  height: 2.5vw;

  background: #ffe4e4;
  border-radius: 8px;
  border: 1px solid rgb(255, 203, 203);
}

.half-width {
  width: 50%;
  font-size: 0.8vw;
}

.projects-deletebtn {
  margin-top: 30px;
}

.flex-test {
  display: flex;
  justify-content: space-between;
}

.awards-date-wrapper {
  margin-right: 2%;
  display: inline-block;
  width: 36%;
}
.additional-date-wrapper {
  display: block;
  width: 50%;
}

.slider {
  padding: 0 !important;
  -webkit-appearance: none;
  width: 100%;
  height: 8px !important;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin: 1rem;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1.4rem;
  height: 1.4rem;
  background: #04aa6d;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}
.review-grid {
  display: grid;
  grid-template-columns: 2fr 4fr 1fr;
  margin-top: 40px;
}
.subtitle-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
@media only screen and (max-width: 557px) {
  .apply-container {
    height: 70vh;
  }
  .apply-page-long h2 {
    font-size: 4.1vw;
  }
  .apply-page-long h3 {
    font-size: 1rem;
  }
  .apply-page-long h4 {
    font-size: 0.8rem;
  }
  .apply-page-long p {
    font-size: 0.7rem;
    line-height: 1rem;
  }
  .apply-wrapper form input {
    width: 85% !important;
    height: 2rem !important;
  }
  
  form textarea {
    width: 85%;
  }
 
  .date-wrapper {
    width: 30% !important;
  }
  .apply-page-long select {
    margin-bottom: 3px;
    height: 3vh !important;
    border-radius: 3px;
  }
  form label {
    line-height: 1.5rem !important;
  }

  .css-2b097c-container {
    width: 50%;
  }
  
  .half-width {
    width: 50%;
    font-size: 0.8vw;
  }

  .projects-deletebtn {
    margin-top: 30px;
  }

  .flex-test {
    display: flex;
    justify-content: space-between;
  }

  .banner {
    display: none;
    text-align: center;
    margin: 0;
    width: 100%;
    background-color: lightcoral;
    padding: 20px;

    margin-bottom: 10px;
  }

  .awards-date-wrapper {
    margin-right: 2%;
    display: inline-block;
    width: 36%;
  }
  .additional-date-wrapper {
    display: block;
    width: 50%;
  }

  .slider {
    padding: 0 !important;
    -webkit-appearance: none;
    width: 100%;
    height: 8px !important;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    margin: 1rem;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.4rem;
    height: 1.4rem;
    background: #04aa6d;
    cursor: pointer;
    border-radius: 50%;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04aa6d;
    cursor: pointer;
  }
  .review-grid {
    display: grid;
    grid-template-columns: 2fr 4fr 1fr;
    margin-top: 40px;
  }
  .apply-deletebtn {
    width: 20%;
    height: 5.5vw;
  }
  .css-yk16xz-control {
    width: 80%;
  }

  .css-1pahdxg-control {
    min-width: 300px;
  }

  .css-2613qy-menu {
    min-width: 200px;
  }
}
