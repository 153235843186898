.company-card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
    gap: 35px;
    justify-items: center;
    margin: 50px 0;
    width: 75%;
    padding: 0 20px;
    margin-left: 12%;
}
  
/* Individual card styling */
.company-card {
    background-color: white;
    border-radius: 20px;
    border: 3px solid #000000;
    width: 300px; /* Adjust width */
    height: 300px; /* Adjust height */
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    transition: transform 0.3s ease;
    position: relative;
    overflow: hidden;
}
  
.company-card:hover {
    transform: translateY(-10px);
}
  
.company-logo {
    max-width: 100px; /* Adjust size for the logo */
    height: auto;
    /* margin-bottom: 15px; */
}
  
.card-content h3 {
    font-size: 18px;
    color: #213546;
    margin-bottom: 10px;
}
  
.card-button {
    background-color: #213546;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
}
  
.card-button:hover {
    background-color: #17303a;
}

@media only screen and (max-width: 600px) {
    .company-card-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
        gap: 15px; /* Reduce gap between cards */
        width: 100%; /* Full width */
        padding: 0 10px;
        margin-left: 0;
    }

    .company-card {
        width: 150px; /* Smaller card size */
        height: 200px; /* Smaller card size */
        padding: 10px;
    }

    .company-logo {
        max-width: 70px; /* Smaller logo size */
    }

    .card-content h3 {
        font-size: 14px; /* Smaller text size */
    }

    .card-button {
        padding: 5px 10px; /* Smaller button */
        font-size: 12px; /* Smaller font */
    }
}