.portal-nav-container{
    width:100vw;
    background-color:#56C385;
}
.portal-nav-container .portal-nav-text-container{
    font-weight: 600;
    color:black;
    font-family:Montserrat;
    padding-left:4vw;
    width:20vw;
    /* min-width: 300px; */
    display:flex;
    font-size:calc(22px - 0.25vw);
}
.portal-nav-container .portal-nav-text-container a{
    text-decoration: none;
    color:black;
    cursor: pointer;
    margin-right:20px;
}
@media only screen and (max-width:850px){
    .portal-nav-container{
        display:none;
    }
}
