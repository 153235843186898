.common_button_selected {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #FFFFFF;
  border: none;
  background-color: #213546;
  padding: 10px 20px;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  margin: 20px;
}

.common_button_unselected {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #9D9D9D;
  border: none;
  background-color: #DCD6D6;
  padding: 10px 20px;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  margin: 20px;
}

.back_buttom_unselected {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: black;
  border: none;
  background-color: white;
  padding: 10px 20px;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  margin: 20px;
}

.common {
  padding-top: 100px;
  align-tracks: center;
}

.buttons_back_continue,
.buttons_back_continue:focus,
.buttons_back_continue:active {
  outline: none;
  text-align: center;
}

.loader {
  z-index: 10;
  background-color: #ffffff00;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  animation: loaderBGFadeIn 200ms linear forwards;
}

.spinner {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-top: 5px solid white;
  position: relative;
  top: calc(50% - 50px);
  left: 50%;
  animation: spinner 500ms linear infinite;
}

.questionnaire-box {
  background-color: #7FB898BF;
  position: absolute;
  width: 60%;
  height: 70%;
  left: 20%;
  top: 30%;
  border-radius: 16px;
  margin-top: 1%;
}

.set-up-profile {
  margin: auto;
  font-size: 200%;
  font-weight: 400;
  font-family: 'Montserrat';
  line-height: 49px;
  color:black;
  height: 10%;
  width: 100%;
  margin-bottom: 1%;
  text-align: center;
}

.step-up-profile-by-step {
  text-align: center;
  margin-bottom: 3%;
  justify-content: center;
  width: 100%;
}

.step-up-profile-by-step li {
  width: 54px;
  height: 54px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  color: #A1A1A1;
  background: #E6E6E6;;
  position: relative;
  top: 15px;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Montserrat';
  line-height: 54px;
  margin-bottom: 40px;
  margin: 24px;
}

.step-up-profile-by-step li:not(.active):after  {
  content: '';
  position: absolute;
  top: 50%;
  left: 32px;
  width: 200%;
  height: 2px;
  transform: translateY(-50%);
  border: 2px solid #E6E6E6;;
}

.step-up-profile-by-step li.active:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 32px;
  width: 200%;
  height: 2px;
  transform: translateY(-50%);
  border: 2px solid #213546;
}

.step-up-profile-by-step li:last-child:after {
  border: none;
}

li:first-child::before {
  display: none;
}

.step-up-profile-by-step .active {
  background: #213546;
  color: #FFFFFF;
}

.step-up-profile-by-step .active ~ li::before { 
  background: #213546;

}

@media only screen and (max-width: 768px) {
  .questionnaire-box {
    height: 60%;
  }
  
  .set-up-profile {
    font-size: 24px;
    line-height: 30px;
    height: auto;
    width: 90%;
    margin-bottom: 24px;
  }
  .step-up-profile-by-step {
    width: 95%;
    margin-bottom: 10%;
  }
  .step-up-profile-by-step li {
    width: 7vw;
    height: 7vw;
    font-size: 2.5vw;
    line-height: 7vw;
    margin: 0.5vw;
  }

  .step-up-profile-by-step li:not(.active):after  {   
    top: 50%;
    left: 5vw;
    width: 50%;
    height: 2px;
  }

  .step-up-profile-by-step li.active:after {
    top: 50%;
    left: 5vw;
    width: 50%;
    height: 2px;
  }

  .step-up-profile-by-step li:last-child:after {
    border: none;
  }
}

@media only screen and (max-width: 540px) {
  .set-up-profile {
    font-size: 18px;
    line-height: 30px;
    height: auto;
    width: 90%;
    margin-bottom: 24px;
  }

  .step-up-profile-by-step li {
    display: none;
  }
  .set-up-profile {
    display: none;
  }

  .questionnaire-box {
    height: 0;
  }

}


