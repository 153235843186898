.w-400{
    width: 400px;
}

.p-10{
    padding: 50px;
}

.bg-off-white{
    background-color: #ebebeb;
}

.p-5{
    padding: 20px;
}

.text-xl{
    font-size: x-large;
}

.text-lg{
    font-size: large;
    margin-bottom: 0;
}

.top-0{
    top: 0;
}


.blog-writer input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform:  scale(1.5); /* IE */
  -moz-transform:  scale(1.5); /* FF */
  -webkit-transform:  scale(1.5);/* Safari and Chrome */
  -o-transform:  scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 5px;
}


.mb-5{
    margin-bottom: 24px;
}
.rounded-lg{
    border-radius: 10px;
}

.bg-ig-blue{
    background-color: #213546;
}

.border-none{
    border: white solid;
}