.homepage {
    display: flex;
    flex-direction: column;
    background-color: #CDE1D6;
    overflow-x: hidden; 
}

.homepage .landing {
    display: flex;
    flex-direction: column; /* Changed from row to column */
    justify-content: center; /* Center vertically */
    align-items: center;
    background-color: #CDE1D6; /* Background color set to Green */
    background-size: cover;
    padding: 0 4vw;
    height: 70vh; 
    text-align: center; /* Center text */
}

.homepage .landing .left-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
}

.homepage .landing .left-container h1 {
    font-size: 60px;
    font-weight: 600;
    width: 70%;
    margin-bottom: 20px;
    -webkit-font-smoothing: antialiased;
}

.homepage .landing .left-container p {
    font-size: 25px;
    font-weight: 400;
    margin: 16px 0 32px;
}

.homepage .landing .left-container a {
    text-decoration: none;
    width: 144px;
}

.homepage .landing .left-container a .button {
    display: flex;
    background-color: #213546;
    border-radius: 10px;
    width: 144px;
    height: 48px;
    margin: 16px 0;
}

.homepage .landing .left-container a .button p {
    margin: auto;
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.homepage .landing .left-container .button-container {
    display: flex;
    gap: 16px;
    justify-content: center; /* Center buttons horizontally */
}

.homepage .landing .left-container a .cheetah-button {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 10px;
    width: 144px;
    height: 48px;
    margin: 16px 0;
}

.homepage .landing .left-container a .cheetah-button p {
    margin: auto;
    color: #213546;
    font-size: 16px;
    font-weight: 600;
}

.homepage .landing .right-container img {
    width: 45vw;
    object-fit: contain;
    image-rendering: crisp-edges;
}

.stats-inline {
    display: flex;
    gap: 45px; /* Adjust space between items */
    margin-top: 25px;
}

.stats-inline p {
    margin: 0;
    font-size: 12px;
}

.stats-inline p strong {
    font-size: 24px;
    color: #34d399; /* Adjust to fit your design */
}

.hiring-stats-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the start */
    margin-top: 10%;
    margin-left: 8%;
    background-color: #CDE1D6;
    padding: 20px;
}

.stats-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 30%;
}

.fields-slider {
    width: 70%; /* Adjust width as needed */
    overflow: hidden;
    background-color: #CDE1D6;
}

.button-container {
    /* margin-top: 3%; */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin-bottom: 7%;
}

.button-container a {
    text-decoration: none;
}

.button-container .button {
    display: flex;
    background-color: #213546;
    border-radius: 10px;
    width: max-content;
    height: 48px;
    margin: 0; /* Ensure there's no extra margin */
    justify-content: center;
    align-items: center;
    padding: 0 20px; /* Add left and right padding */
}

.button-container .button p {
    margin: 0;
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.logo-track {
    display: flex;
    animation: scroll-logos 25s linear infinite;
    margin-bottom: 8%;
}

.logo-item {
    width: 200px; /* Adjust based on your logo size */
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center; /* Center logo horizontally */
    align-items: center; /* Center logo vertically */
}

.outsourcing-solution {
    width: 100%;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
}

.companies-header {
    font-size: 32px; 
    margin-bottom: 15px; 
    font-weight: 600;
    text-align: center;
    margin-top: 3%;
}

.outsourcing-heading {
    font-size: 32px; /* Large font size for section heading */
    margin-bottom: 10px; /* Space between heading and description */
    font-weight: bold;
}

.outsourcing-description {
    font-size: 20px; /* Smaller font size for descriptive text */
    margin-bottom: 30px; /* Extra space below the text before next section */
}

.how-it-works-1-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 10%;
    justify-content: space-between;
}

.how-it-works-1-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.how-it-works-3-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.how-it-works-1-heading {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
}

.how-it-works-1-description {
    font-size: 18px;
    margin-bottom: 20px;
    width: 80%;
}

.image1-styling {
    max-width: 65%;
    height: auto;
}

.how-it-works-1-partnership {
    width: 100%;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.company-mock-section-section {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 30px;
    margin-top: 9%;
    text-align: center;
}

.how-it-works-2-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3%;
}

.how-it-works-2-heading {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: left;
}

.how-it-works-2-description {
    font-size: 18px;
    margin-bottom: 20px;
    width: 80%;
    text-align: left;
}

.how-it-works-2-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.image2-styling {
    max-width: 70%; 
    width: auto; 
    height: auto; 
}

.how-it-works-2-text {
    margin-left: -35%;
}

.how-it-works-3-description {
    font-size: 18px;
    margin-bottom: 50px;
    width: 60%;
}

.how-it-works-3-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12%;
    padding-right: 3%;
}

.image3-styling {
    max-width: 100%;
    max-height: 50%; 
    width: auto; 
    height: auto;
}

.card-content h3 {
    margin-top: 10px;
    color: #000000;
    font-size: 20px;
    font-weight: bold;
}

.card-content .title {
    color: #555;
    font-size: 14px;
    margin-bottom: 10px;
}

.card-content .quote {
    font-size: 15px;
    color: #000000;
    margin-top: 20px;
    font-weight: 500;
}

.card {
    background: #FFFFFF;
    border: 3px solid #000000;
    border-radius: 20px;
    width: 340px;
    padding: 20px;
    height: 400px;
    position: relative;
    overflow: hidden;
    margin: auto;
}

.card-top {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.card-image-styling {
    max-width: 100%;
    max-height: 100px; /* Adjust height as needed */
    width: auto; 
    height: auto;
    margin: 0; /* Remove margin */
    padding: 0; /* Remove padding */
}

.cta-container {
    margin-top: 10%;
    margin-bottom: 3%;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
}

.cta-text {
    font-size: 32px; /* Large font size for section heading */
    margin-bottom: 10px; /* Space between heading and description */
    font-weight: bold;
    text-align: center; /* Ensures text aligns left */
}

.carousel {
    width: 100%;
    padding: 0 150px;
}

.component_five_container .calendly_inner_container {
    width: 100vw;
    min-height: 750px;
    position: relative;
}

.component_five_container {
    width: 95vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 7%;
    margin-left: 2%;
}

.homepage .internships {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #CDE1D6;
    padding: 64px 8vw;
}

.cheetah-mode-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 45px;
    border-radius: 35px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 15%;
    width: 70%;
    margin-top: 3%;
    margin-bottom: 3%;
    animation: rainbow-border 5s linear infinite;
    border-image: linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet) 1;
    border: 5px solid transparent;
}

@keyframes rainbow-border {
    0% {
      border-color: black;
    }
    14.3% {
      border-color: white;
    }
    28.6% {
      border-color: black;
    }
    42.9% {
      border-color: white;
    }
    57.2% {
      border-color: black;
    }
    71.5% {
      border-color: white;
    }
    85.8% {
      border-color: black;
    }
    100% {
      border-color: white;
    }
  }

.cheetah-mode-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.cheetah-mode-content h2 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: left;
}

.cheetah-mode-content p {
    font-size: 16px;
    margin-bottom: 10px;
    width: 60%;
}

.cheetah-apply-button {
    background-color: #ccc;
    margin-top: 20px;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: not-allowed; /* Change as needed */
}

.cheetah-mode-upload {
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-box {
    border: 2px dashed #ccc;
    border-radius: 20px;
    padding: 40px;
    max-width: 500px; 
    height: 200px; 
    text-align: center;
}

.upload-box input[type="file"] {
    display: none;
}

.upload-box label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.upload-box label img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.upload-box label p {
    font-size: 14px;
    margin: 5px 0;
}

.loader3 {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0; /* Add left to cover the entire screen */
    height: 100vh;
    width: 100%;
    backdrop-filter: blur(6px);
    text-align: center;
    justify-content: center;
    align-items: center;
}

.loader3::-webkit-scrollbar {
    display: none;
}
  
.loader2 {
    border: 8px solid #f3f3f300;
    border-radius: 50%;
    border-top: 8px solid #4fc984;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite; 
    animation: spin 2s linear infinite;
}

@media only screen and (max-width: 900px) {
    .homepage .landing {
        justify-content: center;
        text-align: center;
    }
    .homepage .landing .left-container {
        align-items: center;
    }
    .homepage .landing .right-container {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .stats-inline {
        display: flex;
        gap: 25px;
        margin-top: 25px;
    }

    .companies-header {
        margin-top: 16%;
        font-size: 26px; 
        margin-bottom: 25px; 
    }

    .homepage .landing {
        height: 55vh;
    }

    .homepage .landing .left-container h1 {
        width: 100%;
        font-size: 30px;
    }

    .homepage .landing .left-container p {
        font-size: 16px;
    }

    .logo-track {
        display: flex;
        animation: scroll-logos 15s linear infinite;
        margin-bottom: 12%;
    }

    .logo-item {
        width: 200px; /* Adjust based on your logo size */
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: center; /* Center logo horizontally */
        align-items: center; /* Center logo vertically */
    }

    .button-container {
        margin-top: 0%;
        /* display: none;  */
    }

    .homepage .hiring-stats-container {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-left: 1%;
    }

    .stats-left {
        width: 100%;
        margin-bottom: 20px;
    }

    .fields-slider {
        width: 100%;
    }

    .fields-track {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
    }

    .fields-line {
        width: 100%;
    }

    .field {
        font-size: 12px;
        padding: 3px 8px;
        width: auto;
    }

    .outsourcing-solution {
        flex-direction: column; /* Stack items vertically on small screens */
        text-align: center; /* Center-align text */
        width: 100%;
        margin-top: 20%;
        display: flex;
        align-items: flex-start; 
        padding-left: 3%;
    }

    .outsourcing-heading,
    .outsourcing-description {
        /* text-align: left;  */
        margin-top: 3px;
    }

    .how-it-works-1-section {
        flex-direction: column-reverse; /* Stack the image above the text */
        align-items: center;
    }

    .how-it-works-1-content, .how-it-works-1-partnership {
        width: 100%;
        text-align: center; 
    }

    .how-it-works-1-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .how-it-works-3-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .how-it-works-1-description {
        font-size: 18px;
        margin-bottom: 30%;
        text-align: left;
        width: 100%;
    }

    .how-it-works-1-heading {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 15px;
        text-align: left;
    }

    .image1-styling {
        margin-bottom: 1px;
        max-width: 90%;
        margin-top: 10%;
        /* margin-left: 15%; */
    }

    .how-it-works-2-section {
        flex-direction: column; /* Stack items vertically */
        align-items: center;
    }

    .how-it-works-2-text {
        margin-left: 7%;
    }

    .how-it-works-2-content {
        width: 100%; /* Ensure full width for the content */
        text-align: center; /* Center-align the text */
        display: flex;
        flex-direction: column;
    }

    .how-it-works-2-partnership {
        margin-top: -10%; /* Add space above the image */
        width: 100%; /* Ensure full width for the image container */
        text-align: center; /* Center-align the image */
    }

    .image2-styling {
        max-width: 90%; /* Ensure the image is responsive */
        /* height: auto;  */
        margin-left: 3%;
        /* margin-bottom: 10%; */
    }

    .how-it-works-3-description {
        font-size: 18px;
        margin-bottom: 1px;
        width: 90%;
        text-align: left;
    }

    .image3-styling {
        max-width: 130%;
        max-height: 50%; 
        margin-left: -20%;
        width: auto; 
        height: auto;
    }

    .cta-container {
        display: flex;
        margin-top: 30%;
        padding: 0 30px;
        margin-bottom: 5%;
        margin-left: 5%;
    }

    .cta-text {
        margin-right: 2%; /* Space between text and button */
        font-size: 32px; /* Large font size for section heading */
        margin-bottom: 10px; /* Space between heading and description */
        font-weight: bold;
        text-align: left; 
    }

    .carousel {
        width: 100%;
        padding: 0 10px;
    }

    .component_five_container .calendly_inner_container {
        width: 90vw;
        min-height: 750px;
        position: relative;
        margin-top: 20px;
    }

    .component_five_container {
        margin-top: 35%;
        width: 95vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30%;
        margin-left: 0%;
    }

    .homepage .internships {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #CDE1D6;
        padding: 2px 8vw;
    }

    .cheetah-mode-container {
        flex-direction: column; 
        align-items: center; 
        padding: 20px; 
        width: 90%; 
        margin: auto; 
        margin-bottom: 30%;
    }

    .cheetah-mode-content p{
        align-items: center; /* Center align content */
        text-align: left; /* Center text */
        width: 100%; /* Full width */
    }

    .cheetah-mode-upload {
        width: 100%; /* Full width */
        text-align: center; /* Center align upload box */
        margin-top: 15%; /* Add margin above the upload box */
    }

    .upload-box {
        width: 100%; /* Full width */
        padding: 20px; /* Adjust padding for mobile */
    }

    .cheetah-apply-button {
        width: 100%; /* Full width */
        margin-top: 20px; /* Add margin above the button */
    }

    .company-mock-section-section {
        margin-bottom: 30px;
        margin-top: 15%;
        text-align: center;
        width: 90%;
        margin-left: 4%;
    }

}

.homepage .steps {
    display: flex;
    flex-direction: column;
    padding: 64px 12vw;
}

.homepage .steps .step-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.homepage .steps .step-item img {
    width: 20vw;
    object-fit: contain;
    margin: 0 4vw;
}

.homepage .steps .step-item .text-container {
    display: flex;
    flex-direction: column;
}

.homepage .steps .step-item .text-container h3 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 32px;
}

.homepage .steps .step-item .text-container p {
    font-size: 17.6px;
    line-height: 180%;
}

.logo-slider {
    width: 100%;
    overflow-x: hidden;
    background-color: #CDE1D6; /* Background color set to Green */
}

.trusted-text {
    font-size: 14px;  /* Smaller font size for subtlety */
    text-align: center;  /* Center-align the text */
    font-weight: bold;
    padding: 20px 0;  /* Add some padding above and below for spacing */
    text-transform: uppercase;  /* Uppercase text for emphasis */
    letter-spacing: 1px;  /* Increase letter spacing for readability */
    font-family: 'Montserrat';
}

.logo-item img {
    max-width: 110px;
    max-height: 40px; /* Limit logo height */
    width: auto; /* Maintain aspect ratio */
}

.stats-heading {
    font-size: 24px; /* Adjust based on your design */
    font-weight: bold;
    margin-bottom: 20px; /* Add space between the text and button */
}

.fields-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Space between fields */
    align-items: center; /* Center align the fields vertically */
}

.field {
    background-color: #f0f0f0;
    color: #333;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.fields-line {
    display: flex;
    gap: 10px; /* Space between fields */
}

.fields-track {
    display: flex;
    flex-direction: column;
    gap: 10px;
    animation: scroll-fields 20s linear infinite;
}

.field:hover {
    box-shadow: 0 6px 12px rgba(0,0,0,0.2); /* Increased shadow on hover for a lifting effect */
    cursor: pointer; /* Cursor change to indicate interactivity */
}

@keyframes scroll-fields {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes scroll-logos {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.swiper-pagination-bullet {
    background-color: black !important; /* Inactive bullet color */
    opacity: 1; /* Ensure full opacity */
}

.swiper-pagination-bullet-active {
    background-color: #22C55E !important; /* Active bullet color */
}

.component_five_container .calendly_header {
    margin-top: 3%;
    font-size: 35px;
    font-weight: bold;
    width: 75vw;
    text-align: left;
}

.component_five_container .calendly_subtext {
    margin-top: 10px;
    font-size: 21px;
    font-weight: 400;
    width: 75vw;
    text-align: left;
}

@media only screen and (max-width: 900px) {
    .homepage .steps .step-item {
        flex-direction: column;
    }
    .homepage .steps .right-step {
        flex-direction: column-reverse;
    }
    .homepage .steps .step-item img {
        width: 30vw;
    }
}

@media only screen and (max-width: 600px) {
    .homepage .steps .step-item img {
        width: 40vw;
    }
    .homepage .steps .step-item .text-container h3 {
        font-size: 20px;
        text-align: center;
    }
    .homepage .steps .step-item .text-container p {
        font-size: 16px;
    }
}

.homepage .reviews {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #C9E7D5;
    padding: 64px 8vw;
}

.homepage .reviews h3 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 32px;
}

.homepage .reviews .carousel-wrapper {
    position: relative;
}

.homepage .reviews .carousel-wrapper .carousel {
    width: 84vw;
    position: unset;
}

.homepage .reviews .carousel-wrapper .carousel .review-item {
    display: flex;
    flex-direction: column;
    height: 250px;
    background-color: white;
    margin: 0 16px;
    border-radius: 8px;
}

.homepage .reviews .carousel-wrapper .carousel .review-item .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 16px;
}

.homepage .reviews .carousel-wrapper .carousel .review-item .inner-container p {
    font-size: 12.8px;
}

/* .homepage .reviews .carousel-wrapper .carousel .review-item .inner-container .bottom-container {

} */

.homepage .reviews .carousel-wrapper .carousel .review-item .inner-container .bottom-container h5 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 4px;
}

/* .homepage .reviews .carousel-wrapper .carousel .review-item .inner-container .bottom-container p {

} */

.homepage .reviews .react-multi-carousel-list .react-multiple-carousel__arrow--left {
    left: -5vw;
}

.homepage .reviews .react-multi-carousel-list .react-multiple-carousel__arrow--right {
    right: -5vw;
}

.homepage .reviews .react-multi-carousel-list .react-multi-carousel-dot button {
    background-color: #8EC7A6;
    border: none;
    position: relative;
    bottom: -40px;
}

.homepage .reviews .react-multi-carousel-list .react-multi-carousel-dot--active button {
    background-color: #1DAF5C;
}

.homepage .reviews .carousel-wrapper .review-item {
    display: none;
}

.calendly_container {
    margin-left: 7%;
}

@media only screen and (max-width: 600px) {
    .homepage .reviews h3 {
        font-size: 20px;
    }
    .homepage .reviews .carousel-wrapper {
        display: flex;
        flex-direction: column;
    }
    .homepage .reviews .carousel-wrapper .carousel {
        display: none;
    }
    .homepage .reviews .carousel-wrapper .review-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: white;
        height: 200px;
        border-radius: 8px;
        margin: 16px 0;
    }
    .homepage .reviews .carousel-wrapper .review-item .inner-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin: 16px;
    }
    .homepage .reviews .carousel-wrapper .review-item .inner-container h5 {
        font-size: 14px;
        font-weight: 600;
        margin-top: 4px;
    }
    .homepage .reviews .carousel-wrapper .review-item .inner-container p {
        font-size: 12.8px;
    }
}

.homepage .internships h3 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 64px;
}

.homepage .internships .jobs-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    margin-bottom: 96px;
}

.homepage .internships .jobs-container .job-item {
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
}

.homepage .internships .jobs-container .job-item img {
    width: 64px;
    margin-right: 16px;
}

.homepage .internships .jobs-container .job-item .text-container {
    display: flex;
    flex-direction: column;
}

.homepage .internships .jobs-container .job-item .text-container .bottom-container {
    display: flex;
    flex-direction: column;
}

.homepage .internships .jobs-container .job-item .text-container .bottom-container h5 {
    font-size: 16px;
    font-weight: 600;
    color: black;
}

.homepage .internships .jobs-container .job-item .text-container .bottom-container .bottom h6 {
    font-size: 13.6px;
    font-weight: 500;
    color: black;
    margin: 8px 0 4px;
}

.homepage .internships .jobs-container .job-item .text-container .bottom-container .bottom p {
    font-size: 12px;
    font-weight: 400;
    color: black;
}

.homepage .internships h4 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
}

.homepage .internships a {
    text-decoration: none;
}

.homepage .internships a .button {
    display: flex;
    background-color: #1DAF5C;
    border-radius: 5px;
    width: 144px;
    height: 48px;
    margin: 16px 0;
}

.homepage .internships a .button p {
    margin: auto;
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.faq-section {
    margin-top: 7%;
    padding: 0 10px;
    max-width: auto;
    margin-left: 11%;
    margin-right: 15%;
}

.practiceNoraCard {
    background-color: #213546;
    border-radius: 25px;
    width: 80vw; 
    height: 30vh;
    position: relative;
    margin-top: 7%;
    margin-bottom: 8%;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
    margin-left: 10%;
}

.practiceNoraCard-mobile {
    display: flex;
    justify-content: center;
    align-items: center; 
    background-color: #213546;
    border-radius: 25px;
    width: 90vw; 
    height: 30vh;
    position: relative;
    margin-top: 10vh;
    margin-bottom: 8%;
    margin-left: 17px;
}

.practiceNoraCardHead {
    color: white;
    font-size: 30px;
    font-weight: 600;
    width: 50vw;
    padding-bottom: 20px;
}

.practiceNoraCardHead-mobile {
    color: white;
    font-size: 25px;
    font-weight: 600;
    width: 65vw;
    padding-bottom: 20px;
}

.practiceNoraButton {
    background-color: white;
    color: #213546;
    height: 30px;
    width: 170px;
    border-radius: 5px;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    border: none;
}

@media only screen and (max-width: 900px) {
    .homepage .internships .jobs-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 600px) {
    .homepage .internships h3 {
        font-size: 20px;
    }
    .homepage .internships h4 {
        font-size: 20px;
        text-align: center;
    }
    .homepage .internships .jobs-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .faq-section {
        margin-top: 30%;
        margin-left: 7%;
        margin-right: 7%;
    }
}

.faq-item {
    background: #ffffff;
    margin-bottom: 15px;
    border-radius: 8px;
    overflow: hidden;
    width: 80vw;
}

.faq-question {
    width: 80vw;
    padding: 20px;
    background-color: #ffffff;
    border: none;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.faq-question:focus {
    outline: none; /* Ensure no outline on focus */
    box-shadow: none; /* Remove any potential box-shadow */
}

.faq-answer {
    max-height: 0;
    font-size: 16px;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
}

.faq-item.expanded .faq-answer {
    max-height: 1000px; /* Adjust this value as needed */
    padding: 20px 20px 50px;
}

@media only screen and (max-width: 600px) {
    .faq-item.expanded .faq-answer {
        padding: 20px 20px 60px; /* Increased bottom padding for mobile */
    }
}

.scroll-to-top-button {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 1000;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #1DAF5C;
    color: white;
    border: none;
    cursor: pointer;
    padding: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.scroll-to-top-button .button-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-to-top-button svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
}

.scroll-to-top-button .arrow {
    position: relative;
    z-index: 1;
    font-size: 24px;
}

@media (max-width: 600px) {
    .scroll-to-top-button {
        width: 45px;
        height: 45px;
        bottom: 20px;
        right: 20px;
    }

    .scroll-to-top-button .arrow {
        font-size: 20px;
    }
}