.main {
    display: flex;
    flex-direction: column;
    background-color: #CDE1D6;
    font-family: 'Montserrat';
    overflow-x: hidden; 
}

.side-menu {
    width: 200px;
    background-color: #CDE1D6;
    padding: 20px;
    position: fixed;
    bottom: 25%;
}
  
.side-menu ul {
    list-style-type: none;
    padding: 0;
}
  
.side-menu li {
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 17px;
}
  
.side-menu li:hover {
    font-weight: bold;
}

.content {
    margin-left: 7%; 
}

.back-to-dashboard {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: #213546;
    cursor: pointer;
    margin-top: 4%;
  }
  
  .back-icon {
    font-size: 25px;
    color: #213546;
    margin-right: 10px;
  }

.header-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Space between the header and the sub-title */
    margin-left: -6%;
  }
  
  .dashboard-link {
    font-size: 16px;
    color: #213546;
    text-decoration: none;
    font-weight: 500;
    margin-left: 10px; 
  }

.title-heading {
    font-size: 35px;
    margin-bottom: 10px;
    font-weight: bold;
    margin-top: 4%;
    margin-left: 13%;
}

.feedback-section h3 {
    color: #213546;
    margin-top: 5px;
    margin-left: 18%;
    font-size: 22px; 
}
  
.feedback-section h4 {
    color: #213546;
    margin-top: 5px;
}

.overview-section {
    margin-top: 4%;
    margin-right: 5%;
    margin-bottom: 2%;
}

.summary-section {
    background-color: #eef5f1;
    padding: 20px;
    margin-top: 1%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 18%;
    margin-right: 15%;
}

.skills-section {
    display: flex;
    justify-content: space-between;
    margin-left: 18%;
}

.technical-skills-summary {
    width: 47%;
    margin-right: 2%;
    background-color: #eef5f1;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.soft-skills-summary {
    width: 48%;
    background-color: #eef5f1;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 18%;
}

.question-analysis {
    margin-top: 3%;
    margin-bottom: 5%;
}

.question-analysis h5 {
    margin-bottom: 10px;
    color: #333;
}

.question-analysis p {
    margin: 5px 0;
}

.technical-section {
    margin-left: 18%;
    margin-top: 4%;
    margin-right: 25%;
    margin-bottom: 3%;
}

.section {
    background-color: #eef5f1;
    padding: 20px;
    border-radius: 10px;
    margin-top: 3%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.technical-button {
    background-color: #eef5f1;
    border: none;
    width: 100%;
    padding-bottom: 1.7rem;
    border-bottom: black solid 1px;
    padding-left: 0;
    font-family: 'Montserrat';
    line-height: 1.5;
}

.technical-button:focus {
    outline: none;
  }

.sectional-general-styling {
    margin-top: 7%;
    margin-bottom: 7%;
}

.start-button {
    background-color: #1a1f26;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.6em 1.2em; /* Increase padding to make the button larger */
    cursor: pointer;
    font-size: 1.2em; /* Increase font size for larger text */
    line-height: 1; /* Keeps the text height in sync with the content size */
    align-self: center; /* Center the button vertically */
}

.soft-section-border {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 2%;
    background-color: #eef5f1;
}

.soft-skill-section {
    margin-left: 18%;
    margin-top: 4%;
    margin-right: 25%;
    margin-bottom: 3%;
}

.next-steps-section {
    margin-left: 18%;
    margin-top: 4%;
    margin-right: 25%;
    margin-bottom: 3%;
}

.message-history-feedback {
    margin-left: 18%;
    margin-right: 25%;
    margin-top: 4%;
    margin-bottom: 3%;
}

#h3-title {
    color: grey;
    font-weight: 900;
} 

#h4-title {
    font-weight: 700;
}

#dropdown-title {
    font-weight: 600;
}

#dropdown-paragraph {
    line-height: 1.5;
}

.select-section {
    background-color: #CDE1D6;
    margin-left: 18%;
    margin-right: 25%;
    position: relative;
    display: none;
}

#select-style {
    color: grey;
    font-weight: 550;
    font-size:22px;
    width:100%;
    height: 100%;
}

.select-section:focus {
    outline: none;
    box-shadow: none;
}

.text-left {
    font-size: 12px;
}

.select-section div {
    width: 100%;
}

.MuiPaper-root ul {
    color: black;
    background-color: white;
}

#select-style:focus {
    background-color: #CDE1D6;
}

.filter-section {
    width: 100px;
    height: 25px;
    margin-bottom: 30px;
}

#filter-style {
    color: white;
    background-color: #060862;
    font-weight: 550;
    font-size:18px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px 10px 5px 10px;
    align-items: center;
}

.transcript-heading {
    display: flex;
    justify-content: space-between;
}

.sub-title {
    margin-left: 18%;
}

.transcript-title {
    color: grey;
    font-weight: 900;
    margin-top: 10px;
}

.transcript-style {
    background-color: #eef5f1;
    padding: 20px;
    border-radius: 10px;
    margin-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 600px) {
    .select-section {
        display: block;
    }

    .skills-section {
        flex-direction: column;
    }
    .technical-skills-summary {
        width: 82%;
    }
    .soft-skills-summary {
        width: 82%;
    }

    .select-section {
        margin-top: 10%;
    }
    #h3-title {
        height: 0;
        display: none;
    }
    .side-menu {
        display: none;
    }
    .next-steps-section {
        margin-top: 0;
        padding: 1px;
    } 
    .message-history-feedback {
        margin-top: 0;
    }
    .soft-skill-section {
        margin-top: 0;
    }
    .technical-section {
        margin-top: 0;
    }
    .overview-section {
        margin-top: 0;
        padding-top: 0;
    }

    .transcript-heading {
        flex-direction: column;
    }

    .transcript-title {
        height: 0;
        display: none;
    }
  }