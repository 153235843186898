.common_button {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: black;
  border: none;
  background-color: white;
  padding: 10px 20px;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  color: black;
}

.unclicked {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: black;
  border: none;
  background-color: #DCD6D6;
  padding: 10px 20px;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
}

.CenterQ {
  text-align: center;
}

.selected,
.selected:focus,
.selected:active {
  margin: 10px;
  outline: none;
  color: #fff;
  border: none;
  background-color: #035D89;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 10px 20px;
  border-style: solid;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
}

.unselected,
.unselected:focus,
.unselected:active {
  margin: 10px;
  outline: none;
  color: black;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border: none;
  background-color: #ffffff00;
  border-style: solid;
  padding: 10px 20px;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
}

.unselected:hover {
  background-color: gray;
}

.questionnaire-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-yk16xz-control {
  min-width: 200px;
}

.centerText {
  text-align: center;
}

.centerSubtitle {
  width: 157px;
  height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  font-family: 'Montserrat';
  color: black;
  flex:none;
  order: 0;
  flex-grow: 0;
  margin: auto;
}

@media only screen and (max-width: 540px) {
  .centerSubtitle {
    font-size: 15px;
  }

  .unselected {
    font-size: 10px;
  }

  .selected {
    font-size: 10px;
  }

}


