
.fbbutton {
  border: none;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-around;
  align-items: space-around;
  height:30%;
  overflow: hidden;
  margin-top: 3%;

} 
.external_icon_container{
  display: flex;
  justify-content: space-evenly;
  min-width:10vw;
}
.linkedin__btn > p {
  width: calc(100% - 100px);
  margin: 0;
}

.kep-login-facebook.metro{
  height: 4.5rem;
  font-size:0;
  width:4.5rem;
  z-index:100;
  border-radius: 20% !important;
  padding:0;
  cursor:pointer;
  border:1px transparent;
  background:url("./fb.png");
  background-position:center ;
  background-repeat:no-repeat;
 background-size:cover;
 
}
.fbbutton > span > button,


.abcRioButtonContentWrapper{
  
 
  display:flex ;
  flex-direction:column;
  width:4.6rem;
  max-height:4.1rem !important; 
  overflow:hidden !important;
  border-radius:200px;
  background:transparent !important;
}
.abcRioButtonIcon {
  width: 100%;
  margin-top:7%;
  width: auto;
  padding:0 !important;
  background:white;
  z-index:20;
  display:flex;
  justify-content:center;
  place-items: center;
  margin-left: -92%;
}

#google-sign-in > div{
  height:100% !important;
  width:100% !important;
  border-radius: 5px;
}
#google-sign-in.google_btn{
  height:35px;
  width:35px;

}
div.abcRioButtonContentWrapper{
  height:100%;
  width:100%;
  position: relative;
  
}
div.abcRioButtonIcon{
  display:flex;
  position: relative;
  top:8%;
  left:59%;
}
div.abcRioButtonContentWrapper .abcRioButtonSvgImageWithFallback{
  height:35px !important;
  width:35px !important;
  position: relative;

}
.abcRioButtonIconImage{
  margin:0 !important;
  
}
div.abcRioButtonIcon{
  width: 100% !important;
  display: flex;
  height: 100% !important;
  align-self: center !important;
  justify-self: center !important;
  z-index: 10;
  padding: 0 !important;
}
svg.abcRioButtonSvg{
  position: relative;
  width:25px !important;
  height:25px !important;


}
.abcRioButtonIcon svg{
  margin:0;
}
.linkedin__btn {
  display: flex;
  align-items: center;
  background-color: white;
  color: rgb(80, 80, 80);
  width: 35px;
  height:35px;
  padding:1px;
  border-radius: 8px;
  font-size: 18px;
  font-size:3rem;

}
.linkedin__btn > svg {
  width:100%;
  height:100%;
  font-size:3rem;
}

.linkedin__icon {
  width:20%;
  height:20%;
  font-size: 50px;
  color: #0073B1;
 padding:5px;
  margin:0;
}

@media only screen and (max-width:900px){
  .external_icon_container{
    display: flex;
    justify-content: space-evenly;
    min-width:20vw;
  }
  .login-pic-wrap {
    height: auto;
    width: 25vw;
  }
}