.nora-form {
  display: flex;
  justify-content: space-between;
  height: 90vh;
  box-sizing: border-box;
  position: relative;
  padding-left: 3%;
  padding-bottom: 30px;
  padding-right: 3%;
  padding-top: 20px;
}

.controls-section {
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ccc9c9;
  position: relative;
}

.ig-zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 10%;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-wrapper {
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  bottom: -60px;
}

.person-zoom,
.video-feed {
  max-width: 75%;
  object-fit: cover;
  transform: scaleX(-1);
}

.video-feed.hidden {
  display: none;
}

.audio-visualizer {
  position: absolute;
  bottom: 10px;
  right: 20%;
  z-index: 10;
}

.mic-styling {
  width: 70px; 
  height: 40px;
  border-radius: 10px;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.recordBtn {
  padding: 10px 20px;
  background-color: #FFFFFFE5;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 30px;
  flex: none;
  text-align: center;
  transition: opacity 0.3s ease;
}

.recordBtn:disabled {
  opacity: 0;
  visibility: hidden;
  cursor: default;
}

.endInterviewBtn {
  padding: 10px 0; 
  background-color: #213546; /* Match the background color from the screenshot */
  color: white; /* White text */
  border: none;
  border-radius: 10px; /* Rounded button */
  cursor: pointer;
  width: 30%; /* Cover 90% of the parent container */
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  position: fixed;
  bottom: 6%;
}

.round-btn {
  border-radius: 20px;
}

.stop-recording {
  background-color: #FF4A4A; 
  color: white; 
}

.person-zoom-bottom {
  max-width: 10%;
}

.transcript-container {
  flex: 1;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.message-history {
  margin-top: 20px;
  margin-bottom: 20%;
  margin-top: 10%;
}

.custom-modal-box {
  display: flex;  
  justify-content: space-between;  
  align-items: flex-start;  
  padding: 40px;
  background-color: #F4FAF7;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  text-align: left;
  flex-wrap: nowrap; 
}

.modal-left {
  width: 55%;  /* Allocate space for the left section */
  flex-shrink: 0; /* Prevent shrinking */
  margin-top: 3%;
}

.modal-right {
  width: 40%;  /* Allocate space for the right section */
  text-align: center;  /* Center-align content on the right */
  flex-shrink: 0; /* Prevent shrinking */
  margin-top: 12%;
}

.modal-title {
  font-size: 26px;
  font-weight: bold;
  color: #2C3E50;
  margin-bottom: 20px;
}

.modal-subtitle {
  font-size: 18px;
  color: #2C3E50;
  margin-bottom: 40px;
}

.modal-plan {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
}

.plan-title {
  font-size: 18px;
  font-weight: bold;
  color: #2C3E50;
  margin-bottom: 5px;
}

.plan-price {
  font-size: 18px;
  font-weight: 400;
}

.benefits-list {
  padding: 0;
  margin-top: 30px; 
}

.benefits-list li {
  text-align: left;
  margin-bottom: 15px; 
  font-size: 16px; 
  color: #2C3E50; 
}

.check-icon {
  color: #56C385; 
  font-weight: bold;
  margin-right: 10px; 
  font-size: 18px; 
}

.error-message {
  display: flex;
  align-items: center;
  background-color: #f8d7da; 
  color: #c82333; 
  border: 1px solid #c82333; 
  padding: 10px 20px; 
  border-radius: 5px;
  font-size: 1.1em;
  margin: 20px auto; 
  font-weight: 600;
}

.error-icon {
  width: 24px;
  height: 24px;
  margin-right: 20px; /* Space between icon and text */
}

.error-message-modal {
  text-align: center;
  padding: 20px;
}

.error-icon-modal {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.error-message-modal h2 {
  color: red;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10%;
}

.error-message-modal p {
  color: #333;
  font-size: 1.2rem;
}

.error-message-modal a {
  color: #213546;
  font-weight: bold;
  text-decoration: none;
}

.error-message-modal a:hover {
  text-decoration: underline;
}

.message {
  display: block;
  max-width: 80%;
  padding: 10px;
  margin: 20px 0;
  border-radius: 8px;
  position: relative;
  font-size: 1em;
  line-height: 1.4;
}

.message::before {
  position: absolute;
  top: -20px;
  font-weight: bold;
  font-size: 0.9em;
}

.message.Nora {
  background-color: #f1f1f1;
  text-align: left;
  align-self: flex-start;
}

.message.Nora::before {
  content: "Nora";
  left: 0;
}

.message.User {
  background-color: #d1ecf1;
  text-align: left;
  align-self: flex-end;
  margin-left: auto;
}

.message.User::before {
  content: "You";
  right: 0;
}

.message.Assistant {
  background-color: #d1ecf1;
  text-align: left;
}

.message.Assistant::before {
  content: "Nora";
  left: 0;
}

.left-arrow-styling {
  margin-right: 10px;
  position: absolute;
  width: 20px;
  cursor: pointer;
}

.video-timer-styling {
  margin-right: 10px;
  width: 20px;
  cursor: pointer;
}

.video-on-styling {
  margin-left: 20px;
  width: 40px;
  cursor: pointer;
}

.position-display {
  margin-left: 3%;
  margin-top: 20px;
  position: relative;
  font-size: 1.2em;
  font-weight: 600;
  display: flex;
  width: 100%;
  gap: 5px;
}

.position-text {
  flex-grow: 1;
  font-size: 1.2em;
  margin-left: 5%;
}

.timer-display {
  font-size: 1em;
  font-weight: 600;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #CDE1D6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-text {
  color: "black";
  font-size: 1.5em;
  font-weight: 600;
  margin-top: 20px;
}

.instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 10%; 
  width: 100%; 
  box-sizing: border-box; 
}

.instructionsImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 2%; 
  width: 100%; 
  box-sizing: border-box; 
}

.instructions h1 {
  font-size: 3rem;
  font-weight: 600;
  color: #213546;
  margin-bottom: 1.5rem;
}

.instructions p {
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 4rem;
}

.instruction-warning {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.instruction-warning-Image {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  max-width: 50%;
  margin-left: auto;
  margin-top: 3%;
  margin-right: auto;
}

.exclamation-icon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  flex-shrink: 0;
  margin-top: -5px;
}

.instruction-warning-Image p {
  margin: 0;
  text-align: left;
  font-size: 1.3rem;
}

.instruction-warning p {
  margin: 0;
  text-align: left;
  font-size: 1.3rem;
}

.instructions-intro {
  max-width: 60%;  
  margin-left: auto;
  margin-right: auto;
}

.start-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #213546;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-radius: 10px;
}

@media only screen and (max-width: 1500px) and (min-width: 900px) {
  .person-zoom,
  .video-feed {
    max-width: 90%; 
  }

  .audio-visualizer {
    right: 8%;
  }

}

@media only screen and (max-width: 900px) and (min-width: 600px) {
  .nora-form {
    flex-direction: column;
  }
  .person-zoom {
    min-width: 150px;
  }
  .mic-styling {
    width: 42px;
    height: 24px;
  }

}

@media only screen and (max-width: 600px) {
  .nora-form {
    flex-direction: column;
  }
  .person-zoom {
    min-width: 120px;
  }

  .audio-visualizer {
    right: -15%;
  }

  .mic-styling {
    width: 31px;
    height: 21px;
  }

  .recordBtn {
    width: 30%;
    margin: 0 20px;
  }

  .endInterviewBtn {
    width: 50%;
    bottom: 4%;
  }

  .video-on-styling {
    width: 50px;
    margin-left: 10px;
  }

  .video-wrapper {
    right: 30px;
  }

  .ig-zoom {
    max-width: 20%;
  }

  .position-text {
    margin-left: 10%;
  }

  .instruction-warning {
    display: none;
  }
  
  .instructions {
    margin-top: 100px;
  }

  .instruction-warning-Image {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    max-width: 90%;
    margin-left: auto;
    margin-top: 10%;
    margin-right: auto;
  }
}