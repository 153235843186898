

.landing{

  height: auto;

}
.viewTemplates__main {
  margin: 30px;
  height: auto;
  color: black;
}

.viewTemplates__main > h1 {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
}

.each {
  max-width: 600px;
  width: 60%;
  min-width: 400px;
  aspect-ratio: 1 / 1.414;
}

.bright,
.dull {
  transition: filter 200ms;
}

.bright {
  filter: brightness(85%);
}

.bright:hover,
.bright:focus {
  filter: brightness(95%);
}

.dull {
  filter: brightness(35%);
}

.dull:hover,
.dull:focus {
  filter: brightness(60%);
}

.viewTemplates__templates {
  width: auto;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.viewTemplates__template {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 36px;
  max-width: 600px;
}

.viewTemplates__template > button {
  color: black;
  background-color: transparent;
  border: 1px solid aquamarine;
  margin-top: 12px;
  transition: all 200ms;
  cursor: pointer;
}

.viewTemplates__template > button:hover {
  color: black;
  background-color: aquamarine;
}

.viewTemplates__text {
  margin-top: 12px;
  text-align: center;
  width: 400px;
}

.viewTemplates__text > p {
  margin: 0;
}

@media (max-width: 1400px) {
  .viewTemplates__templates {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 900px) {
  .viewTemplates__templates {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
