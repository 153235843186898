.interviewCompleted-feedback-section {
    margin-top: 40px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  
  .interviewCompleted-feedback-question {
    font-size: 16px;
    color: #333;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .interviewCompleted-feedback-textarea {
    width: 100%;
    height: 120px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 12px;
    line-height: 1.4;
    margin-bottom: 20px;
    resize: none;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .interviewCompleted-feedback-textarea:focus {
    border-color: #4caf50;
    box-shadow: 0px 0px 5px rgba(76, 175, 80, 0.5);
  }
  
  .interviewCompleted-submit-feedback-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #4caf50;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .interviewCompleted-submit-feedback-button:hover {
    background-color: #45a049;
  }
  
  .interviewCompleted-submit-feedback-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }