@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

body {
  background-color: #203546;
  margin: 0;
  max-width: 100vw;
  color: #CDE1D6;
  font-family: 'Montserrat';
  overflow-x: hidden;

}

html, body, #root, .App {
  height: 100%;
  width: 100%;
}

.newNavbarApp {
  height: 100%;
  width: 100%;
  background-color: #CDE1D6;
}

.odd {
  background-color: #035da9;
}

body .even {
  background-color: #01203a;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

.__loader {
  z-index: 10;
  background-color: #ffffff00;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  animation: loaderBGFadeIn 200ms linear forwards;
}

.__loaderText {
  position: inherit;
  top: 60%;
  width: 100%;
  text-align: center;
  opacity: 0;
  animation: opacityFadeIn 200ms linear forwards;
}

.__spinner {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-top: 5px solid white;
  position: relative;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  animation: spinner 500ms linear infinite;
}

@keyframes loaderBGFadeIn {
  0% {
    background-color: #ffffff00;
  }
  100% {
    background-color: #ffffff20;
  }
}

@keyframes opacityFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
