
.user-input-holder {
  display: flex;
  justify-content: center;
  width: 100%;
}

.user-input-label {
  font-size: 1rem;
  font-weight: 300;
  white-space: nowrap;
  transform: translateY(0.5rem);
  margin-right: 10px;
  margin-top: 7px;
}

.user-input {
  height: 2.5rem;
  width: 100%;
  padding-left: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: black 1.5px solid;
  outline: none;
  background: transparent;
  margin-top: 0.5rem;
  font-family: 'Montserrat';
  margin-bottom: 10px;
}

.bg-img-login{
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 100vh; 
  padding: 0 2rem;
}

.ig-logo{
  display: block;
  width: 12vw;
  margin-right:-20px; 
  cursor:pointer;
  position: absolute;
  top:20px;
  left: 20px;
}

.grid-wrapper {
  display: -webkit-box; /* Safari compatibility */
  display: -ms-flexbox; /* IE 10 compatibility */
  display: flex; /* Modern browsers */
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.grid-wrapper h1
{
    color: #213546;
    font-size: calc(25px + 1.5vw);
    font-weight: 600;
    text-align: center;
    width:100%;
}

.loginwith {
  font-weight: 500;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 15px;
  margin-bottom: 15px;
}

.white-box {
  background-color: white; 
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);  /* Soft shadow for depth */
  padding: 3rem; /* Padding inside the box */
  display: flex; /* Flexbox for layout */
  justify-content: space-between; /* Space between the image and form */
  align-items: center;
  width: 75vw; 
  max-width: 1200px; 
  margin: auto;
  margin-top: 50px;
}

.login-pic-wrap {
  width: 35vw;
  max-height: 500px;
  max-width: 35%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.login-pic {
  background-color: #213546;
  width: 85%;
  border-radius: 45px; 
}

.login-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:45vw;
  margin-top: 10px;
}

#wrongCredentials
{
  margin:auto;
  display:none;
  background-color: lightcoral;
  padding: 20px;
  width:60%;
  border-radius: 10px;
}

#invalidSignUp
{
  margin:auto;
  display:none;
  background-color: lightcoral;
  padding: 20px;
  width:60%;
  border-radius: 10px;
}

#userSessionExpired
{
  margin:auto;
  background-color: lightcoral;
  padding: 20px;
  width:60%;
  border-radius: 10px;
}

.text-fields-container{
  width: 30vw;
}
 
.text-input-signup {
  position: relative;
  display: flex;
}    
       
.text-input {
  height: 2.8rem;
  width: 100%;
  padding-left: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: black 1.5px solid;
  outline: none;
  background: transparent;
  margin-top: 0.5rem;
  font-family: 'Montserrat';
  margin-bottom: 1rem;
  font-size: 1rem;
}

.external-auth-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4285f4; 
  color: white;
  border-radius: 0.5rem;
  width: 100%; 
  max-width: 30vw; 
  height: 3rem; 
  margin: 1.5rem 0; 
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  outline: none;
}

.external-auth-btn img {
  margin-right: 1rem; 
}

.external-auth-btn:hover {
  background-color: #357ae8; 
}

.password_forgot{
  width:67%;
}
.login__forgot-link {
  text-decoration: underline;
  font-weight: 200;
  background: none;
  border: none;
  justify-self: flex-start
}

  .forgot-pass__warning {
    color: #F00;
    font-size: 11px;
    margin-top: 1rem;
    margin-bottom: -2rem;
  }

  .forgot-pass__reset-btn-div {
    margin-top: 5rem;
  }
  

  .login-loginbtn {
    display: flex;
    background-color: #213546;
    border-radius: 5px;
    width: 100%; 
    max-width: 65%;
    height: 3rem;
    margin: 1.5rem 0;
    cursor: pointer;
    color:white;
    font-size: 1.2rem;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    border:none;
    outline: none;
  }

  .login-loginbtn:hover {
    border: 1px solid #213546;
  }

  .login-loginbtn:active {
    border:none;
    outline: none;
    transform:scale(0.9);
  }

.bottom-text{
 font-size:1.4rem;
 margin-top:1%;
 margin-bottom:1%;
 height:1.4rem;
}
.password {
  position:relative;
  margin-top:1.5rem;
  width:90%;
  display:flex;
  justify-content:center;
  background:transparent;
}

.text-input-signup .eyeicon{
  position:absolute;
  right: 3%;
  bottom: 1.4rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.text-input-signup .lg-eyeicon{
  position: absolute;
  right: 3%;
  bottom: 1.4rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.title .highlight {
  color: #2ecc71; /* Make "Future" green */
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 30vw;
  margin: 1.2rem 0;
}

.divider hr {
  border: none;
  border-top: 1px solid #e0e0e0; 
  width: 45%; 
  height: 1px; 
  margin: 0; 
}

.divider span {
  margin: 0 1rem; 
  color: #888888;
  font-size: 1rem;
  font-weight: 400;
}

.btm-text{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size:1.2rem; 
  margin-top: 10px;
}

.btm-text .mx-2{
  font-size: 1.2rem;
  font-weight: 400;
}

.pass-modal-div {
  position: absolute;
  width: 100vw;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(233, 233, 233, 0.75);
  z-index: 100;
}

.login-modal__inner-div {
  width: 100%;
  height: 100%;
}

.forgot-pass_container {
  display: flex;
  flex-direction: column;
  width: 35%;
  padding: 2rem 4rem 6rem 4rem;
  border: solid black 2px;
  border-radius: 10px;
  background-color: #B8D9C5;
}

.forgot-pass__exit-btn {
  width: min-content;
  align-self: flex-end;
  background: none;
  border: none;
  margin-right: -2rem;
}

.forgot-pass__header {
color: #213546;
font-size: 30px;
font-weight: 600;
letter-spacing: 0.6px;
}

.forgot-pass__body {
  width: 80%;
}


.forgot-pass__bold {
  font-weight: 600;
}

.forgot-pass__text-input {
  border: #213546 solid 1px;
  background-color: rgba(255, 255, 255, 0.52);
  width: 70%;
  min-width: 20rem;
}

.forgot-pass_reset-btn {
  /* border-radius: ; */
  border: none;
  padding: .8rem 3rem;
  border-radius: 5px;
  color: white;
  background: #213546;
  
}

.forgot-pass__success-p {
  font-size: 18px;
}

.external_form{
  margin:0;
}

@media only screen and (max-width:1024px) {
  .grid-wrapper{
    flex-direction: column; /* Change to column to place image at the top */
    justify-content: start;
  }

  .white-box {
    width: 88vw; 
    padding: 0;
    margin-top: 35%;
    padding-top: 20px;
  }
  
  .ig-logo{
    width:25vw;
  }
  .grid-wrapper h1{
    width:80vw;
    font-size:3.3rem;
  }

  .login-pic-wrap {
    height: auto;
    width: 100%;
  }

  .bg-img-login{
    display: flex;
    flex-direction: column;
    background-size: cover;
    padding: 0;
  }
  form{
    height:100%;
    padding-bottom: 5vh;
  }
  .login-details{
    width:75vw;
    /* height: 100vh; */
    margin-top: 30px;
  }
  .password_forgot {
    width: 41%;
  }
  .forgot-pass_container{
    width: 60%;
    height: 40%;
    padding: 1em 4rem 0rem 3rem;
  }
  .login-loginbtn{
    height:15vh
  }
}
@media only screen and (max-width: 710px){
  .grid-wrapper h1 {
      font-size: 2rem;   
  }
  .login-loginbtn{
    height: 5vh
  }
  .text-fields-container {
    width: 75vw;
  }
  
  .password_forgot{
    width:100%;
  }

  .login-pic-wrap{
    min-width: 200px;
  }

  .btm-text{
     font-size:1rem; 
  }
  .btm-text .mx-2{
    font-size: 1rem;
  }
}
@media only screen and (max-width: 510px){
  .external_form{
    width:30vw;
  }
  .forgot-pass_container{
    width: 90%;
    height: 50%;
    padding: 1em 4rem 0rem 3rem;
  }
  #invalidSignUp
  {
  width:95%;
  }
}
@media only screen and (max-width: 390px){
  .external_form{
    width:35vw;
  }
}
@media only screen and (max-width: 300px){
  .login-pic-wrap{
    min-width: 150px;
  }
}
