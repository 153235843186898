.maintext{
  display: flex;
  justify-content: space-between;
  padding: 40px;
  background-color: #05747450;
  color: #213546;
}

.leftside{
    width: 50%;
    float: left;
  }

  .rightside{
    width: 50%;
    float: right;
  }

  .smallHeading{
    font-size: 20px;
  }

  .box{
    margin: 10px;
    padding: 40px;
    background-color:#05747450 ;
  }

  .section-header {
    display: flex;
    align-items: center;
    margin: 24px;
  }

  .header-title{
    margin-bottom: 0;
    margin-left: 10px;
    color: #213546;
  }

  .MuiMenu-paper {
    background-color: #255b68 !important;
    color: white !important;
  }

  .tag-button {
    border: 2px solid;
    border-image-slice: 1;
    background-color: #255b68;
    color: #213546;
    margin: 0 10px 10px 0;
  }
  .border-gradient-purple {
    border-image-source: linear-gradient(to left, #b78efd, #d671b1);
    color: #213546;
  }
  .tag-edit {
    border: 2px solid rgb(207, 228, 255);
    background-color: #255b68;
    color: white;
    transition: 0.5s;
    margin-right: 10px;
  }
  .tag-edit:hover{
    background-color: rgb(207, 228, 255);
    color: black;
  }

    