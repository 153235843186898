.noraHome {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px; 
    margin-top: 7%;
    margin-bottom: 10%;
}

.text {
    text-align: center;
    width: 80vw;
}

.headText {
    font-size: 60px;
    font-weight: 600;
}

.headText-mobile{
    font-size: 38px;
    font-weight: 600;  
}

.subText {
    font-size: 20px;
    padding-top: 40px;
}

.subText-mobile {
    font-size: 14px;
    padding-top: 40px; 
}

.noraBtn {
    width: 250px;
    height: 50px;
    background-color: #213546;
    color: white;
    font-size: 20px;
    border-radius: 10px;
    position: relative;
    top: 40px;
    border: none
}

.noraBtn-mobile {
    width: 150px;
    height: 40px;
    background-color: #213546;
    color: white;
    font-size: 13px;
    font-weight: 500; 
    border-radius: 10px;
    position: relative;
    top: 40px;
    border: none 
}



@media (max-width: 1024px) {
    .interview {
        display: none
    }
}