.bottom-text {
  display: flex;
  margin: auto;
  justify-content: center;
  font-family: Montserrat;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}
.bottom-text a{
  color: #213546;
  font-weight: bold;
}
