.contact-page {
  display: flex;
  flex-direction: column;
}

hr {
  border: 1px solid #565656;
  background-color: #565656;
  height: calc(80vh - 10px);
}

.contact-page-forms {
  background-color: #11212b;
}

@media (max-width: 970px) {
  /* .contact-section {
    flex-direction: column;
    margin-bottom: 100px;
  }
  .contact-section > * {
    width: 500px;
    margin-bottom: 32px;
  } */
}

@media (max-width: 767px) {
  /* .contact-section > * {
    width: 100%;
  } */
}

/* ************************* updated styling below ************************ */

.contact__accordian-btn {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
  width: 100%;
  padding-bottom: 1.7rem;
  border-bottom: black solid 1px;
  padding-left: 0;
}

.contact__accordian-div {
  margin: 7rem 0 7rem 0;
  width: 60%;
  min-width: 50rem;
}

.contact-page h2 {
  font-weight: 600;
}

.contact__accordian-body {
  line-height: 2rem;
  padding: 1.5rem 1rem;
  font-weight: 200;
}

.contact-page button:focus {
  outline: none;
}

@media(max-width: 576px) {
  .contact__accordian-btn {
    font-size: 2.7vw;
    font-weight: 600;
    /* width: 100%;
    padding-bottom: 1.7rem;
    border-bottom: black solid 1px;
    padding-left: 0; */
  }

  .contact__accordian-div {
    margin: 7rem 0 7rem 0;
    width: 90vw;
    min-width: 90vw;
  }
}