.mock-interview-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #CDE1D6;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 60px;
    padding-top: 10px;
}

.noraRow {
    display: flex;
    justify-content: space-between;
    width: 80vw; 
    padding-bottom: 50px; 
    align-items: center;
    margin-top: 5%;
}

.alignLeft {
    text-align: left;
    width: 80vw; 
}

.noraRowHead {
    font-family: Montserrat; 
    font-weight: 700;
    font-size: 32px;
}

.noraRowHead-mobile{
    font-family: Montserrat; 
    font-weight: 900;
    font-size: 25px;    
    margin-top: 10vh;
}

.noraRowText {
    font-size: 20px; 
    width: 38vw; 
}

.noraRowText-mobile {
    font-size: 14px; 
    padding-bottom: 3vh;
}

.imageStyle-mobile{
    max-width: 85%;
    height: auto;
    text-align: center;
    position: relative;
    left: 5vw;
    padding-bottom: 5vh;
}

.imageStyle1 {
    max-width: 42%;
    height: auto;
}

.imageStyle2 {
    max-width: 45%;
    height: auto;
}

.imageStyle3 {
    max-width: 45%;
    height: auto;
}

.pad {
    margin-top: 10%;
}

.pad-mobile {
    margin-top: 10vh;
}

.interviewReviews {
    font-family: Montserrat; 
    font-weight: 700;
    font-size: 32px;
    margin-top: 8%;
    margin-bottom: 4vh;
}