.resumaker {
  padding: 2rem 1.5rem;
  overflow-y: hidden;
}

.resumaker__form {
  padding: 30px;
  height: 75vh;
  overflow-y: scroll;
  padding-bottom: 50vh;
}

.resumaker__form::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.resumaker__form::-webkit-scrollbar-button:start:decrement,
.resumaker__form::-webkit-scrollbar-button:end:increment {
  display: block;
  height: 0;
  background-color: transparent;
}

.resumaker__form::-webkit-scrollbar-thumb:vertical {
  height: 50px;
  background-color: #04e4a89c;
  -webkit-border-radius: 8px;
}
.resumaker__form::-webkit-scrollbar-thumb:horizontal {
  width: 50px;
  background-color: #04e4a89c;
  -webkit-border-radius: 8px;
}

.resumaker__container {
  display: grid;
  min-height: 75vh;
  grid-template-columns: 1fr 3fr;
  background-color: #05747450;
  box-shadow: -3px 4px 5px #053d3d50;
  border-radius: 6px;
}

.resumaker__progressContainer {
  display: flex;
  width: 100%;
  border: 2px solid black;
  height: 24px;
  margin-top: 12px;
}

.resumaker__progressContainer > p {
  margin-left: 6px;
}

.resumaker__progress {
  transition: all 250ms;
  background-color: white;
  display: flex;
  justify-content: flex-end;
}

.resumaker__resumeItem {
  padding: 1rem 0.5rem;
  transition: all 150ms;
  cursor: pointer;
  border-bottom: 1px solid aquamarine;
}

.resumaker__resumeItem:hover {
  background-color: #7fffd455;
}

.resumaker__formList {
  padding-left: 30px;
  padding-top: 24px;
  border-right: 1px solid #057474a9;
}

.resumaker__formList > h5 {
  cursor: pointer;
  transition: all 200ms;
  width: 150px;
  border-bottom: 1px solid #00000000;
}

.resumaker__formList > h5:hover {
  color: #05e2a7;
  border-bottom: 1px solid #04e4a8;
}

.resumaker__meter {
  transition: all 100ms;
  width: 100%;
}

.resumaker__createFlex {
  display: flex;
}

.resumaker__createInput {
  border: none;
  outline: none;
  caret-color: #04e4a8;
  background-color: transparent;
  border-bottom: 1px solid #04e4a8;
  color: white;
  width: 250px;
  margin-right: 12px;
}

.resumaker__createInput::placeholder {
  color: white;
}

.resumaker__createCheckbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 12px;
  cursor: pointer;
}

.resumaker__checkContainer {
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.resumaker__checkContainer > label {
  cursor: pointer;
}

.resumaker__createButton {
  transition: all 200ms;
  border: 1px solid aquamarine;
  color: white;
  background-color: transparent;
}

.resumaker__createButton:hover {
  color: black;
  background-color: aquamarine;
}
.resumaker_deletebtn {
  transition: all 200ms;

  color: white;
  border: 1px solid rgb(219, 0, 0);
  background-color: #05747450;
}
.resumaker_deletebtn:hover {
  color: white;
  border: 1px solid rgb(148, 0, 0);
  background-color: rgb(148, 0, 0);
}

@media (max-width: 768px) {
  .resumaker__formList {
    display: none;
  }
  .resumaker__container {
    grid-template-columns: 1fr;
  }
}
