.bg-img {
    display: flex;
    justify-content: center;
    height: 100vh;
}

.password-reset-form {
    width: 50%;
    margin: 7rem auto 0 auto;
    color: black;
    border: 2px solid white;
    padding: 5rem 3.5rem;
    background-color: rgba(255, 255, 255, 0.54);
    border-radius: 25px;
    
}

.reset-pass-form-title {
    width: 90%;
    margin: auto;
    font-size: xx-large;
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: center;
}

.password-input {
    width: 90%;
    margin: auto;
    border: none;
    border-radius: 10px;
    padding: 8px;
}

.password-restraint-div {
    width: 90%;
    margin: 15px auto 15px auto;
    display: flex;
    flex-direction: row;
    font-size: small;
}

.password-restraint-1 {
    margin-right: 10px;
}

.password-restraint-2 {
    margin-right: 10px;
}

.password-restraint-text {
    margin-left: 5px;
}

.password-confirm-input-div {
    width: 90%;
    margin: auto;
    position: relative;
    overflow: hidden;
}    

.password-confirm-input {
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 8px;
    margin-top: 5px;
}

.eyeicon {
    color: gray;
    position: absolute;
    right: 5%;
    bottom: 0.7rem;
    width: 25px;
    height: 25px;
    cursor: pointer;
    padding: 1px;
    border-radius: 5px;
}

.forgot-pass__reset-btn-div {
    margin: 0;
}

.forgot-pass_reset-btn {
    margin: 2rem auto 0 auto;
}

.forgot-pass_reset-btn-disabled {
    background-color: lightgray;
    color: gray;
    padding: 12px 45px;
    border: none;
    border-radius: 5px;
    margin: 2rem auto 0 auto;
}

@media (max-width: 1560px) {
    .password-reset-form {
        width: 50%;
    }
}

@media (max-width: 1230px) {
    .password-reset-form {
        width: 50%;
    }

    .reset-pass-form-title {
        font-size: x-large;
    }

    .password-restraint-div {
        flex-direction: column;
    }
}

@media (max-width: 770px) {
    .password-reset-form {
        width: 60%;
    }
    
    .reset-pass-form-title {
        font-size: large;
    }
}

@media (max-width: 600px) {
    .password-reset-form {
        width:95%;
        margin: 1.7rem;
    }
}

/* Stylings for reset password complete form */

.password-reset-complete-div {
    width: 80%;
    margin: 7rem auto 0 auto;
    color: black;
    border: 2px solid white;
    padding: 2rem 3.5rem;
    background-color: rgba(255, 255, 255, 0.54);
    border-radius: 25px;
    text-align: center;
}

.password-reset-complete-checkmark {
    margin-bottom: 5px;
}

.forgot-pass_reset-btn-text {
    color: white;
}

.forgot-pass_reset-btn-text:hover {
    color: white;
    text-decoration: none;
}
@media (max-width: 1230px) {
    .password-reset-complete-div {
        width: 50%;
    }
}

@media (max-width: 600px) {
    .password-reset-complete-div {
        width: 80%;
    }
}
