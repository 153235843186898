.w-full{
    width: 100%;
}

.h-full{
    height: 100%;
}

.h-screen{
    height: 100%;
}

.p-60{
    padding: 60px;
}

.p-40{
    padding: 40px;
}

.p-30{
    padding: 30px;
}

.p-screen{
    padding: 150px;
    padding-top: 60px;
}

.background-1{
    background-color: #CDE1D6;
    background-size: cover;
}

.text-center{
    text-align: center;
}

.font-bold{
    font-weight: 600;
}

.justify-center{
    justify-content: center;
}

.justify-between{
    justify-content: space-between;
}


.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col{
    display: flex;
    flex-direction: column;
}

.flex-wrap{
    flex-wrap: wrap;
}

.w-400{
    width: 400px;
}

.items-center{
    align-items: center;
}

.outline-none{
    outline: none;
}

.rounded-lg{
    border-radius: 10px;
}

.p-1{
    padding: 1.5rem;
}

.border-grey{
    border: 1px solid #d1d1d1;
}

.mb-5{
    margin-bottom: 24px;
}

.mb-10{
    margin-bottom: 40px;
}

.mt-100{
    margin-top: 100px;
}

.justify-evenly{
    justify-content: space-evenly;
}

.rounded-xl{
    border-radius: 15px;
}

.bg-white{
    background-color: rgb(218, 218, 218);
}

.rounded-2xl{
    border-radius: 20px;
}

.grid-cols{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust the minimum and maximum width as needed */
        gap: 16px; /* Adjust the gap between items as needed */
        justify-content: space-between;
}

.read-more-button{
    background-color: #213546;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    width: 150px;
    border: 1px solid #d1d1d1;
    font-weight: 600;

    &:hover{
        background-color: #172531;
    }
}

@media screen and (max-width: 860px){
    .mobile-hidden{
        display: none;
    }

    .mobile-post{
        padding: 30px;
        height: 50%;
    }

    .mobile-flex{
        display: flex;
        flex-direction: column !important;
    }

    .mobile-header{
        font-size: 24px;
    }

    .mobile-desc{
        padding-top: 10px;
    }

    .mobile-width{
        width: 100%;
    }

    .mobile-height{
        height: 700px;
    }  
}
@media screen and (max-width: 1288px){
    .mobile-card{
        width: 100%;
        height: 700px;
    }
}
.featured-desc{
    padding-top: 20px;
    font-size: 15px;
    font-weight: 400;
}
@media screen and (max-width: 860px){
    .mobile-hidden{
        display: none;
    }

    .mobile-post{
        padding: 30px;
    }

    .mobile-flex{
        display: flex;
        flex-direction: column !important;
    }

    .mobile-header{
        font-size: 24px;
    }

    .mobile-desc{
        padding-top: 10px;
    }

    .m-width{
        width: 100%;
    
    }
}

@media screen and (max-width: 1288px){
    .mobile-p{
        padding: 30px;
    }
    .mobile-button{
        padding: 20px;
        width: 100%;
    }
}
.blog-item{
    margin-bottom: 40px;
}
.blog-item a{
box-sizing: unset !important;
color: black;
text-decoration: none;
}