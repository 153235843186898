.about_us_page{
    padding:0;
    font-family: Montserrat;
}
.history_container{
    background-color: white;
    width:100vw;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.history_banner{
    height:30vh;
    width:100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #213546;
    /* margin-top:8vh; */

    padding:50px;
}
.history_h1_container{
    width:60vw;
}
.history_h1_container > h1{
    font-size:calc(20px + 1.25vw);
    word-spacing: 10px;
    font-weight:600;
    text-align: center;
    color:white;
}
.timeline_title_container{
    width:100vw;
    height:10vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.timeline_title_container > h1{
    padding-right:2.5vw;
    letter-spacing: 0.5vw;
    font-weight: 600;
    font-size:calc(15px + 1.5vw);
}
.history_timeline_container{
    width:100vw;
    display:flex;
    justify-content: flex-end;
    margin-bottom:10vh;
}
.history_timeline{
    max-height:40vh;
    margin-top:1vh;
    margin-right:8vw;
}
.timeline_dot{
    height:20px;
    width:20px;
    background-color: #C9E7D5;
    cursor: pointer;
}
.timeline_connector{
    height:10vh !important;
    background-color:black !important;
    width:3px !important;
}
.active_dot{
    background-color: #275235 !important;
}
.timeline_content{
    padding-top:calc(12px - 0.5vw) !important;
    font-size:calc(10px + 1vw) !important;
    cursor: pointer;
}
.active_content{

    color:#275235;
    font-size:calc(10px + 1.25vw) !important;
    padding-top:calc(15px - 0.15vw) !important;
    font-weight: 500 !important;
}

.history_timeline_text{
    background-color: #C9E7D5;
    width:70vw;
    display:flex;
    align-items: center;
    border-top-left-radius: 1.5vw;
    border-bottom-left-radius: 1.5vw;
}
.history_timeline_text > p{
    font-size:calc(5px + 1.25vw);
    font-weight: 500;
    padding:50px;
}

.review_container{
    background-color: #C9E7D5;

}
.title_container {
    width:100vw;
    display:flex;
    align-items:center;
    justify-content: center;
}
.review_container > .title_container > h1{
    color:#275235;
    font-size:calc(15px + 1.25vw);
    font-weight: 600;
    padding:5vh 2vw 2vh 2vw;
}

.review_container{
    width:100vw;
    display:flex;
    flex-direction: column;
    align-items: center;
}
.review_container .carousel_wrapper_about{
    position:relative;
    margin-bottom: 5vh;
}
.review_container .carousel_wrapper_about .carousel{
    width: 84vw;
    position: unset;
}
.review_container .item{
    display: flex;
    flex-direction: column;
    height: 250px;
    background-color: white;
    margin: 0 16px;
    border-radius: 8px;
    justify-content: center;
    font-weight: 400;
    padding:30px;
    font-size:calc(10px + 0.25vw);
}
.review_container .carousel_wrapper_about .react-multi-carousel-list .react-multiple-carousel__arrow--left{
    left: -5vw;
}
.review_container .carousel_wrapper_about .react-multi-carousel-list .react-multiple-carousel__arrow--right{
    right: -5vw;
}
.review_container .carousel_wrapper_about .react-multi-carousel-list .react-multiple-carousel__arrow{
    background: none;
}
.carousel_wrapper_about .react-multi-carousel-list .react-multi-carousel-dot-list {
    bottom:-5.5vh;
    
    margin-bottom:10px;
}
.carousel_wrapper_about .react-multi-carousel-list .react-multi-carousel-dot button {
    background-color: #8EC7A6;
    border:none;
    position: relative;
}
.carousel_wrapper_about .react-multi-carousel-list .react-multi-carousel-dot--active button{
    background-color: #1DAF5C;
}
.review_bottom_container{
    margin-top: 25px;
    
}
.review_bottom_container > p {
    margin:0;
    font-size:calc(10px + 0.25vw);
}
.review_bottom_container > .reviews_student_name{
    font-weight: 600;
}

.team_container{
    background-color:white;
    width:100vw;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.team_header_container{
    margin-top:5vh;
    width:60vw;
    text-align: center;
}
.team_header_container > h1{
    font-size:calc(15px + 1.25vw);
    font-weight:600;
}
.team_grid{
    width:90vw;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    margin-top:100px;
    margin-bottom: 100px;
    column-gap: 2.5vw;
    row-gap: 2.5vh;
}
.team_grid > .grid_item_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.team_grid > .grid_item_container > img{
    width:calc(150px + 1.5vw);
    border-radius: 50%;
}
.team_grid > .grid_item_container > img:hover{
    border: solid 5px lightblue;
}
.team_grid > .grid_item_container > p{
    margin:0;
}
.team_container .team_grid .grid_name{
    font-size:calc(15px + 0.35vw);
    font-weight:600;
}
.team_container .team_grid .grid_role{
    font-size:calc(10px + 0.35vw);
    font-weight:400;
}

.about_us_page .signup_container{
    background-color: #213546;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.about_us_page .signup_container h1{
    margin-top:5vh;
    color:white;
    font-weight:600;
    font-size:calc(20px + 1vw);
    word-spacing: 1vw;
}
.about_us_page .signup_container .button_container .button{
    background-color: #1daf5c;
    border-radius: 5px;
    display: flex;
    height: 58px;
    margin: 16px 0;
    width: 255px;
}
.about_us_page .signup_container .button_container{
    display: flex;
    width:50vw;
    margin-top:5vh;
    justify-content: space-evenly;
    margin-bottom: 5vh;
}
.about_us_page .signup_container .button_container a{
    text-decoration: none;
    transition: 0.2s all;
}
.about_us_page .signup_container .button_container a:active{
    transform: scale(0.98);
}
.about_us_page .signup_container .button_container p{
    color: #fff;
    font-size:calc(10px + .5vw);
    font-weight: 600;
    margin: auto;
}
@media only screen and (max-width:1200px) {
    .team_grid{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .about_us_page .signup_container .button_container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media only screen and (max-width:900px) {
    .team_grid{
        grid-template-columns: 1fr 1fr;
    }
    .history_timeline_text > p{
        padding:25px;
    }
}
@media only screen and (max-width:600px) {
    .team_grid{
        grid-template-columns: 1fr;
    }
    .history_timeline_text > p{
        padding:25px;
    }
    .history_banner{
        margin-top:0;
    }
    .history_h1_container{
        width:100vw;
    }
    .history_timeline{
        margin-right: 5vw;
    }
    .about_us_page .signup_container .button_container .button{
        height: 38px;
        width: 155px;
    }
    .team_header_container{
        width:80vw;
    }
    .history_timeline_text > p{
        padding:10px;
        height:auto;
    }
}
