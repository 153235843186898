.navbar {
  padding: 2.5rem 4vw 2rem;
  width: 100vw;
  z-index: 2;
  display: flex;
  align-items: center;
}

/* this is for mock interview nav bar */
.navbar.home .navbar__nav a:not(.sign-up-link),
.navbar.home .navbar__nav button:not(.nav-sign-up),
.navbar.home .navbar__nav .dropbtn {
  color: black;
}
.navbar__nav {
  display: flex;
  align-items: center;
}

.newNavbar {
  width: 85vw; /* Full width for a clean fit */
  padding: 1rem 2vw; /* Less padding for compact design */
  z-index: 2;
  display: flex;
  justify-content: space-between; /* Space between logo and navigation */
  align-items: center;
  background-color: #E9F2EB; 
  border-radius: 2rem; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
}

.newNavbar img {
  margin-right: 1rem; /* Space between logo and text */
  margin-top: 15px;
}

.navbar.home {
  background-color: #CDE1D6;
}

.navbar.other {
  background-color: #203546;
}

/* for Mock Interview and Home Page */
.nav-sign-up.home,
.nav-sign-up.mockInterviewer {
  background-color: #213546;
}

.dropdown {
  display:block;
}

.dropbtn {
  margin: 0px 9px;
  backdrop-filter: blur(2px) !important;
  font-size: 20px;
  transition: all 200ms;
  z-index: 39 !important;
  cursor: pointer;
}

.dropbtn.home {
  color: black;
}

.dropbtn.other {
  color: white;
}

.dropdown__icon {
  margin-left: 3px;
}

.navbar__logo {
  cursor: pointer;
}

.navbar__logo > img {
  width: 15vw;
  min-width: 148px;
  transform: translateY(-12px);
}

@media only screen and (max-width: 850px) {
  .navbar__logo > img {
    transform: translateY(0);
  }
}

.navbar__social {
  width: 30px;
  height: 30px;
  color: white;
  margin: 0 20px;
  transition: all 200ms ease-in-out;
}

.navbar__social:hover {
  color: #62d633;
}

.navbar__navController {
  text-align: center;
  width: 36px;
  height: 32px;
  border-radius: 4px;
  margin-top: 20px;
}

.navbar__bar {
  width: 26px;
  height: 2px;
  background-color: black;
  margin: 6px auto;
}

.navbar__nav a {
  float: left;
}

.dropdown {
  float: left;
}

.studentsEmployers {
  float: left;
  display : none;
}

.studentsEmployersLeft {
  display: inline-flex;
}

.studentDropDownActive {
  background-color: lightgray;
  font-weight: lighter;
  display : block;
}

.studentDropDownInActive {
  display: none;
}

.employerDropDownActive {
  background-color: lightgray;
  font-weight: lighter;
  display : block;
}

.employerDropDownInActive {
  display:none;
}

.dropdown-contents {
  position:absolute;
  background: rgb(255, 255, 255);
  width: auto;
  z-index: 100;
  right: 4px;
  top: 2rem;
  display:none;
  color: black;
  padding:0;
  margin:0;
}

.dropdown-contents a {
  color: black !important;
  padding: 10px 16px;
  width: 100%;
  text-decoration: none;
  display: flex;
  text-align: left;
  margin: 0;
  background:white;
  position:relative;
  z-index: 12;
  font-size:1rem;
  cursor:pointer;
}

.dropdown:hover .dropdown-contents,
.dropdown:active .dropdown-contents {
 display: block;
 z-index: 12;
}

.dropdown-contents a:hover {
  background-color: #93c8f3;
}

.close-icon-box {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  padding: 0;
  margin-left: auto;
  width: 100%;
  height: 60px;
  text-align: right;
  padding: 0 10px;
  border-bottom: 1px solid rgb(212, 211, 211);
  justify-content:space-between;
  background-color: #CDE1D6;
}

.close-icon {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin-left: auto;
  width: 150%;
  height: 10px;
  text-align: right;
  color:rgb(0, 0, 0);
  margin-right: 15px;
  margin-top: 20px;
}

.close-icon svg {
  width: 24px;
  height: 24px;
  color: black;
}

.close-icon:focus {
  outline:none;
}

.close-icon-box:hover,
.close-icon-box:focus {
  outline: none;
}

@media all and (min-width: 1024px) and (max-width: 1200px) {
  .navbar__nav > .navItem {
    margin: 4px 8px;
  }
  .navbar__social {
    margin: 0 10px;
  }
  .navbar__navController {
    display: none;
  }
}

@media all and (min-width: 769px) {
  .navbar__navController {
    display: none;
  }
  .close-icon {
    display: none;
  }
  .navbar__logoOpenimg {
    display:none !important;
  }
  .close-icon-box {
    display:none !important;
  }
  .open{
    display:none !important;
  }
}                      
                
@media all and (max-width: 850px) {
  .navbar__navController {
    display: block;
    margin-right:10px;
    cursor: pointer;
  }
  .navbar__noDisplay {
    display: none !important;
  }
  .dropdown__icon {
    margin: 0;
  }
  .dropbtn {
    margin: 0;
    float: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 20px;
    cursor: pointer;
    color: black;
  }
  .navbar__nav {
    display: block;
  }
   .noSmall {
    display:none !important;
  }
  .border__bottom {
    border-bottom: 1px solid rgb(212, 211, 211);
  }
}

@media all and (max-width: 1045px) {
  .navbar {
    display: flex;
    width: 100%;
  }
}

@media all and (max-width: 850px) {
  .navbar {
    display: flex;
    width: 100%;
  }
  .navbar__logo {
    flex: 0 0 70%;
    max-width: 40%;
  }
  .navbar__logoimg {
    max-width: 100%;
  }
  .navbar__nav .navItem {
    margin: 0;
    float: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 20px;
    color: #000;
  }
  .navbar__nav .dropdown .navItem span {
    font-size: 10px;
  }
  .navbar__nav .dropdown {
    float: none;
    width: 100%;
  }
  .dropdown-content {
    position: unset;
    background-color: transparent;
    margin: 0;
    box-shadow: none;
    padding: 0 15px;
  }
  .navbar__social {
    color: black;
    margin-top: 10px;
  }
  .noSmall {
    display:none !important;
    transition:.5s
  }
}

@media all and (max-width: 1250px) {
  .noSmall {
    margin-right: 5%;
  }
}

@media all and (max-width: 850px) {
  .navbar__logo {
    align-self: center;
    min-width:100px;
   max-height:60px;  
  }
  .navbar {
    height:90px;
    padding-top:0;
    width:100%;
  }
  .navbar__navController {
    display: block;
  }
  .navItem {
    margin: 4px 0px;
  }
  .navbar__nav {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    place-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;

    transition: all 200ms ease-in-out;
  }
  .studentsEmployers{
    display: block;
  }
  .studentsEmployersLeft{
    display:none;
  }
  .dropdown-content {
    left: 0;
  }
  .noSmall {
    transition:.5s
  }
  .navbar__logoOpenimg{
    margin-top:5px;
    width:60%;
    height:48px;
    
  }
}

.mobile-redirect {
  color: black;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  background-color: #eefcf2; /* Ensure this matches the desired background */
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
  border: none; /* Ensure no border */
}

.mobile-redirect .mobile-nav-item {
  list-style: none;
  text-decoration: none;
  color: black;
  font-size: 15px;
  height: 5vh;
  width: 100%;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0; /* Ensure no margin */
  padding: 10px 20px; /* Adjust padding to ensure no gray bar */
  border-bottom: none; /* Ensure no bottom border */
}
.mobile-redirect .mobile-nav-section{
  padding: 7px 20px;
  font-size: 17px;
}
.mobile-redirect  .mobile-nav-section a{
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  width:100%;
}

.mobile-redirect li.li-dropdown-nav{
  width: 100%;
  display:flex;
  justify-content: space-between;
}
.mobile-redirect li > img{
  width: 15px;
  height:15px;
}
.mobile-redirect li .nav-arrow{
  transition: transform 0.3s;
  align-self: center;
}
.mobile-redirect li .nav-arrow.open{
  transform: rotate(180deg);
}
.mobile-redirect .dropdown-nav{
  display:flex;
  flex-direction: column;
  overflow: hidden;
  font-size:0;
  padding-left: 7px;
  margin-bottom: 0;
}

.close-inactive{
  display:none;
  max-height:0;
}
.dropdown-nav.open-active{
  list-style: none;
  font-weight: 500;
  font-size: 17px;
}
.open-active  a{
  text-decoration: none;
  color:black;
}
.open-active  li{
  height: 5vh;
  width:100%;
  display:flex;
  align-items: center;
}

.mobile-button-nav-container {
  width:100%;
  display: flex;
  justify-content: space-evenly;
  margin-top:10px;
}

.nav-sign-up {
  border-radius: 10px;
  background-color: #1DAF5C;
  border: none;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-left:10px;
  margin-right: 10px;  /* Add right margin to create space between the buttons */
}

.nav-cheetah {
  border-radius: 10px;
  background-color: white;
  border: none;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-left:10px;
  margin-right: 10px;  
}

.mobile-nav-login{
  margin-left: 0;
}
.nav-login{
  border-radius: 5px;
  background-color:#213546;
  border:none;
  padding:10px;
  width:90px;
  height:50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  align-self: center;
  margin-top:10px;
  margin-bottom:10px;
}
.nav-sign-up:active{
  transform: scale(0.9);
}

.nav-sign-up .sign-up-link, .nav-login .login-link{
  color:white;
  text-decoration: none;
}

.mobile-nav-item {
    list-style: none;
    text-decoration: none;
    color:black;
    font-size: 15px;
    height: 5vh;
    width:100%;
    font-weight: 500;
    display:flex;
    align-items: center;
    margin: 0 20px;
    
}

/* Styles for the logged-in state */
.logged-in .mobile-redirect {
  background-color: white; /* Fully white background */
}

.logged-in .close-icon-box {
  background-color: #213546;
}

.logged-in .mobile-nav-item {
  background-color: white; /* Ensure items are white */
}

.logged-in .mobile-nav-login {
  background-color: #1DAF5C; /* Keep logout button green */
}