.mockInterviewerInput-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #CDE1D6;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 60px;
    padding-top: 10px;
}

.main-container {
    display: flex;
    align-items: flex-start;
    position: relative;
    
}

.main-content {
    width: 75%; 
    display: flex;
    flex-direction: column;
    margin-right: 7%;
}

@media  (max-width: 600px) {
    .main-container{
        align-items: center !important; 
        justify-content: center;
        
    }

    .main-content{
        width: 100% !important;
        margin-right: 0% !important;
    }
}


.side-menu-nora {
    width: 200px;
    background-color: #CDE1D6;
    padding: 20px;
    position: fixed;
    bottom: 25%;
    width: 20%; 
    padding-top: 8%;
}

.side-menu-nora ul {
    list-style-type: none;
    padding: 0;
}
  
.side-menu-nora li {
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
}
  
.side-menu-nora li:hover {
    font-weight: bold;
}

.section-title {
    font-size: 4em; 
    font-weight: bold; 
    margin-bottom: -10px;
    text-align: center;
}

.margin-left {
    margin-left: 9.5% !important; 
}

.custom-slider {
    -webkit-appearance: none; 
    appearance: none;
    width: 80%; 
    height: 6px; 
    background: linear-gradient(to right, #56C385 0%, #56C385 calc((var(--val, 50) / 10) * 100%), #fff calc((var(--val, 50) / 10) * 100%), #fff 100%);
    border-radius: 10px; 
    outline: none; 
    transition: background 0.15s ease-in-out; 
    margin: 10px 0;
    margin-right: 1%;
    margin-top: 20px;
}

.custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: #56C385; 
    cursor: pointer; 
    border-radius: 50%; 
    position: relative;
    z-index: 1;
}

.custom-slider::-moz-range-thumb {
    width: 15px; 
    height: 15px; 
    background: #56C385; 
    cursor: pointer; 
    border-radius: 50%; 
    position: relative;
    z-index: 1;
}

.custom-slider::-webkit-slider-runnable-track {
    background: transparent; 
}

.custom-slider::-moz-range-track {
    background: transparent; 
}




.slider-labels {
    display: flex;
    justify-content: space-between;
    margin-top: -20px; 
    position: relative;
    right: 0.5%;
    text-align: center;
    
}

.slider-text{
    width: 28%;
    margin-bottom: 15px;
}

.section-subtitle {
    font-size: 1.35em;
    font-weight: 500;
    margin-bottom: 60px;
    text-align: center;
    max-width: 900px;
    margin-left: auto; 
    margin-right: auto; 
}

.section-title-input {
    font-size: 3em; 
    font-weight: bold; 
    padding-bottom: 50px; 
    text-align: left; 
}

.section-subtitle-input {
    font-size: 2.5em;
    font-weight: 600;
    margin-bottom: 20px;
}
@media  (max-width: 600px) {
    .section-subtitle-input {
        text-align: center;
    }
    .section-title-input{
        text-align: center;
    }
    .interview-screen {
        opacity: 100% !important;
    }
    .slider-text{
        width: 20%;
        margin-bottom: 15px;
    }
}

.margin-top-sections {
    margin-top: 8%;
}

.section-suggestion {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 40px;
}

.menu-add {
    width: 20% !important;
    position: static !important;
}

.inputButton-1 {
    background-color:#213546; 
    font-weight: 600;
    border: none;
    color: white;
    border-radius: 5px; 
    height: 30px;
}

.button-mobile {
    width: 25vw !important;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.inputButton-2 {
    color:#213546; 
    font-weight: 600;
    background-color: white;
    border-radius: 5px; 
    height: 30px;
    width: 10vw;
    border: 1px solid lightslategray; 
}

@media (max-width: 900px) {

    .interview-card{
        padding-top: 2vh !important; 
    }
    
    .inputButton-2{
        font-size: 8px;
    }

    .inputButton-1{
        font-size: 8px;
    }
}

.summary-heading {
    font-weight: bold;   
    margin-top: 15px;  
}

.summary-heading-2 {
    font-weight: bold;   
    margin-top: 30px;  
}

.summary-caption {
    width: 80%; 
}

.interview-summary {
    background-color: white;
    border-radius: 5px; 
    padding: 20px 20px 20px 35px;
    margin-bottom: 7%;
}

.subscription-summary {
    background-color: white;
    border-radius: 5px; 
    padding: 20px 20px 20px 10px;
    margin-bottom: 7%;
    text-align: center;
}

.subscription-caption {
    width: 100%;
    margin-bottom: 20px; 
}

.interview-modal-position {
    margin-top: 5%;
    text-align: left;
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 4%;
}

.interview-modal-jd {
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
}

.interview-modal-jd-text {
    text-align: left;
    margin-bottom: 4%;
}

.fontWeight-600 {
    font-weight: 600
}

.fontWeight-700 { 
    font-weight: 700
}

.completed-interviews {
    display: flex;
    background-color: #8F8F8F4D;
    border-Radius: 10px;
    padding: 2% 3% 2% 4%;
    gap: 6vw;  
    margin-bottom: 2%; 
}

.see-more{
   margin-bottom: 7%;
   color: gray;
   font-size: 14px;
   position: relative;
   left: 91%;  
   font-weight: 700; 

}

.interview-card-info {
    font-weight: 600; 
    font-size: 14px; 
}

.link-button {
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px; 
    margin-top: 7px;
    text-align: left; 
}

.link-button:hover {
    color: #000;
    text-decoration: none; 
}

.bullet-points-left-margin {
    margin-left: 20px;
}

.interview-clock-styling {
    text-align: center;  
    display: flex;
    justify-content: center; 
    align-items: center;  
    white-space: nowrap;  
    font-size: 11px;  
    color: #21354680;
}

.interview-clock-text-styling {
    width: 10px;
}

.next-steps {
    background-color: rgba(143, 143, 143, 0.3);
    padding: 20px 10px 20px 20px;
    border-radius: 10px;
}

.interview-screen {
    background-color: white;
    opacity: 90%;
    border-radius: 10px;
    padding: 30px 50px 30px 50px;
    text-align: center;
    margin-bottom: 2%;
}

.interview-card {
    width: 17vw;
    height: 40vh;
    opacity: 75%;
    background-color: white;
    text-align: center; 
    border-radius: 10px; 
    padding-top: 5vh;
}

.interview-card-mobile{
    width: 60vw;
    height: 32vh;
    
    background-color: white;
    text-align: center; 
    border-radius: 10px; 
    padding-top: 3vh;
}

@media (min-height:430px) and (max-height: 590px) {
    .interview-card {
        padding-top: 2vh;
        height: 50vh;
    }
}

@media (max-height: 430px) {
    .interview-card {
        padding-top: 2vh;
        height: 60vh;
    }
}



.form-container {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 10px;
    align-items: stretch;
    margin-left: 5%;
}

.get-started-button-container {
    display: flex;
    justify-content: center; 
}

.left-section {
    display: flex;
    flex-direction: column;
    width: 45%; /* Fixed width */
}

.left-section .form-group {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.left-section .file-upload {
    position: relative;
    width: 100%;
    height: 100%;
    border: 2px dashed #ccc;
    background-color: #f8f8f8;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
}

.left-section .file-upload .form-control {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.left-section .file-upload-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    pointer-events: none;
}

.left-section .pdf-icon {
    width: 50px;
    height: auto;
    margin-bottom: 10px;
}

.left-section .file-upload-content p {
    margin: 5px 0;
}

.left-section .file-upload-content .file-size {
    font-size: 0.8em;
    color: #777;
}

.left-section .file-name {
    font-size: 1.2em;
    color: #333;
    word-break: break-all;
}

.right-sections {
    width: 55%; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form-section {
    padding: 20px;
    margin-bottom: -20px;
}

.form-group {
    width: 100%;
    margin-bottom: 20px;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

.required-asterisk {
    color: red;
}

.form-control {
    width: 100%; 
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

textarea.form-control {
    height: 100px;
    resize: none;

}

.btn {
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 40px;
    font-size: 1.2em;
}

.btn-primary {
    background-color: #213546 !important;
    color: white;
}

.btn-disabled {
    background-color: #ccc !important;
    color: #999;
    cursor: not-allowed;
}

/* Media Queries */
@media screen and (max-width: 860px) {
    .form-container {
        flex-direction: column;
        align-items: center;
    }

    .section-title-container {
        margin-bottom: 10px; 
    }

    .section-subtitle {
        margin-bottom: 20px; 
    }

    .form-container {
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px; 
    }

    .left-section, .right-sections {
        width: 100%;
    }

    .left-section .file-upload {
        height: 200px; 
    }

    .form-section {
        padding: 10px; 
    }

    .btn {
        margin-top: 20px; 
    }
}
