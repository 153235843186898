.loader3{
    position: absolute;
    z-index: 100;
    top: 0;
    display: flex;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(6px);
    text-align: center;
    justify-content: center;
    align-items: center;
}
.loader3::-webkit-scrollbar {
    display: none;
  }
.loader2 {
    border: 8px solid #f3f3f300;
    border-radius: 50%;
    border-top: 8px solid #4fc984;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite; 
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }