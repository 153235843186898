.common_button{
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    color: black;
    border: none;
    background-color: white;
    padding: 10px 20px;
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
}

.page{
    width: auto;
    color: black;

}

.unclicked{
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    color: black;
    border: none;
    background-color: gray;
    padding: 10px 20px;
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
}

.CenterQ{
    text-align: center;
}
.selected, .selected:focus, .selected:active{
    margin: 10px;
    outline: none;
    color: #fff;
    border: none;
    background-color: #035da9;
    border-top-left-radius:25px;
    border-top-right-radius:25px;
    border-bottom-left-radius:25px;
    border-bottom-right-radius:25px;
    padding: 10px 20px;
    border-style: solid;
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
}

.unselected, .unselected:focus, .unselected:active{
    margin: 10px;
    outline:none;
    color: #fff;
    border-top-left-radius:25px;
    border-top-right-radius:25px;
    border-bottom-left-radius:25px;
    border-bottom-right-radius:25px;
    border: none;
    background-color: #ffffff00;
    border-style: solid;
    padding: 10px 20px;
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
}

.unselected:hover{
    background-color: gray;
}

.centerA{
    display: flex;
    align-items: center;
    justify-content: center;
}

.centerText{
    text-align: center;
}


