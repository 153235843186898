.candidate-application-container {
    background-color: #CDE1D6;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .candidate-application-header {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }
  
  .candidate-application-logo {
    width: 180px;
  }
  
  .candidate-application-content {
    width: 60%;
    padding: 30px;
  }
  
  .position-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .position-location {
    font-size: 1.1rem;
    font-weight: 600;
    color: #666;
    margin-bottom: 20px;
  }
  
  .section-title3 {
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 500;
  }
  
  .company-description,
  .job-description {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .apply-button3 {
    background-color: #233242;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    cursor: pointer;
    margin-top: 30px;
    display: block;
    width: 50%;
    text-align: center;
  }
  
  .apply-button:hover {
    background-color: #1b2a37;
  }
  
  .loading,
  .error {
    text-align: center;
    font-size: 1.5rem;
    color: #333;
  }

  .application-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
  }
  
  .candidate-application-container label {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    color: #333;
    width: 100%;
  }
  
  .candidate-application-container input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .candidate-application-container input:focus {
    border-color: #56C385;
    outline: none;
  }
  
  .form-buttons2 {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .back-button2 {
    background-color: #233242;
    color: white; 
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    cursor: pointer;
  }
  
  .submit-button2 {
    background-color: #233242;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    cursor: pointer;
  }
  
  .submit-button2:hover {
    background-color: #1b2a37;
  }

  @media only screen and (max-width: 600px) {
    .candidate-application-content {
      width: 100%;
    }
  }