.homepage_footer.home {
  background-color: #213546;
}

.homepage_footer.other {
  background-color: #CDE1D6;
}

.homepage_footer.home .footer-content-columns-contain,
.homepage_footer.home .footer-links,
.homepage_footer.home .footer-doc a,
.homepage_footer.home .footer-social-icons a,
.homepage_footer.home .sc-icons,
.homepage_footer.home .footer-content-columns a {
  color: white;
}

.homepage_footer.other .footer-content-columns-contain,
.homepage_footer.other .footer-links,
.homepage_footer.other .footer-doc a,
.homepage_footer.other .footer-social-icons a,
.homepage_footer.other .sc-icons,
.homepage_footer.other .footer-content-columns a {
  color: black;
}

.homepage_footer {
  width: 100%;
  display:flex;
  flex-direction: column;
}
.homepage_footer > .footer-line{
  align-self: center;
  margin-top:30px;
  width:90%;
  background-color:#ECF1F499  ;
  height:1px;
  margin-bottom:5vh;
}
.homepage_footer > .footer-content{
  display:flex;
}
.homepage_footer > .footer-content > .footer-logo-container{
  width:50%;
}
.homepage_footer > .footer-content > .footer-logo-container > .footer_logo{
  width: 350px;
  margin-left: 3vw;
}


.homepage_footer > .footer-content > .footer-content-columns-contain{
  width:50%;
  display:flex;
  justify-content: space-around;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns > h3{
  font-size:calc(15px + 0.25vw);
  font-weight: 600;
  margin-bottom:15px;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns > p > a {
  text-decoration: none;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small{
  display:none;
}
.homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns > p{
  font-size:calc(11px + 0.25vw);
}
.homepage_footer > .footer-links {
  display:flex;
  width:90%;
  align-self: center;
  margin-top:5vh;
  margin-bottom:5px;
}
.homepage_footer > .footer-links > .footer-doc{
  margin:0;
  padding:0;
  width:70%;
  display: flex;
  font-size:calc(13px + 0.25vw);
  align-items: center;
}

.homepage_footer > .footer-links > .footer-doc > .footer-doc-txt{
  display:flex;
}
.homepage_footer > .footer-links > .footer-doc > .footer-doc-txt > p{
  margin:0;
  padding-left:10px;
}
.homepage_footer > .footer-links > .footer-doc > a {
  text-decoration: none;
  margin-left:35px;
}
.homepage_footer > .footer-links > .footer-social-icons{
  width:30%;
  display: flex;
  justify-content: flex-end;
}
.homepage_footer > .footer-links > .footer-social-icons > a{
  width:50px;
}
.homepage_footer > .footer-links > .footer-social-icons > a > .sc-icons{
  height:2.5rem !important;
  width:2.5rem !important;
}

@media only screen and (max-width:900px){
  .homepage_footer > .footer-content{
    display:flex;
    flex-direction: column;
  }
  .homepage_footer > .footer-content > .footer-logo-container{
    width:100%;
    margin-bottom:25px;
  }
  .homepage_footer > .footer-content > .footer-logo-container > .footer_logo{
    width:35vw;
    min-width:150px;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain{
    width:100%;
    flex-wrap: wrap;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-content-columns{
    width:50%;
    padding-left:5vw;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small{
    display:flex;
    height:auto;
    align-items: flex-end;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small > a{
    margin-right:19px;
  }
  .homepage_footer > .footer-content > .footer-content-columns-contain > .footer-social-icons-small > a > .sc-icons{
    height:5vw !important;
    width:5vw !important;
    min-height:25px !important;
    min-width:25px !important;
  }
  .homepage_footer > .footer-links > .footer-social-icons{
    display:none;
  }
  .homepage_footer > .footer-links{
    width:100%;
    margin-top:25px;
  }
  .homepage_footer > .footer-links > .footer-doc {
    flex-direction: column;
    width:100%;
    align-items: start;
    margin-bottom:10px;
  }
  .homepage_footer > .footer-links > .footer-doc > .footer-doc-txt{
    padding-left:5vw;
  }
  .homepage_footer > .footer-links > .footer-doc > .footer-doc-txt > p{
    padding-left: 0;
  }
  .homepage_footer > .footer-links > .footer-doc > .footer-doc-txt > .right-text{
    padding-left: 10px;
  }
  .homepage_footer > .footer-links > .footer-doc > a{
    margin-left:0;
    padding-left:5vw;
    font-size: calc(11px + 0.25vw);
    margin-top:5px;
  }
}