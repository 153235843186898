

.contact-form {
  width: 90%;
  min-width: 28rem;
  max-width: 55rem;
  text-align: center;
  margin: auto;
  color: black;
  border: 2px solid white;
  padding: 2rem 3.5rem;
  background-color: rgba(255, 255, 255, 0.54);
  border-radius: 25px;
  font-weight: 400;
  
}

.contact-form__input {
  border: none;
  border-radius: 5px !important;
}

.contact-form__text-area {
  font-size: 1.25rem;
  width: 100%;
  border-radius: 5px !important;
}

.contact-form__dropdown-btn {
  background-color: white;
  color: rgb(96, 96, 96);
  border-radius: 5px !important;
}

::placeholder {
  color: black;
  opacity: 1;
}

#contact-form__btn {
  font-family: 'Montserrat';
  background-color: rgba(3, 93, 169, 1);
  color: white;
  font-weight: 700;
  font-size: 1.25rem;
  padding: .7rem 3rem;
  border-radius: 5px;
  border: none;
  margin-left: -.75rem;
}

input.form-control {
  height:3rem !important;
}

#contact-form__submitted-message {
  display: none;
  justify-content: center;
  margin-top: 2.5rem;
  font-weight: 600;
  font-size: 15px;
}

@media(max-width: 576px) {

  .contact-form__btn-div {
    justify-content: center;
  
  }

  .contact-form__input, .contact-form__text-area {
    font-size: 3vw !important;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
   font-size: 3vw !important;
  }

  .contact-form__dropdown-btn {
    font-size: 3vw;
    padding-top: .8rem;
    padding-bottom: .8rem;
  }

  #contact-form__btn {
    font-weight: 700;
    font-size: 1rem;
    padding: .7rem 3rem;
    border-radius: 5px;
    border: none;
    margin-left: -.75rem;
  }

  #contact-form__submitted-message {
    justify-content: center;
    margin: 2.5rem 3rem;
    font-weight: 600;
    font-size: 1rem;
  }
    
}

/* *************** none of styling below is used in contact form*/


#notification
{
  display: none;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  
  

}

#success
{
  display : none;
  background-color: #5ea749;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  
}


#failure
{
  text-align:start;
  display: none;
  background-color: lightcoral;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;

}



.contactForm__inputs {
  display: table;
  width: 100%;
  justify-content: space-between;
  color: #203546;
  
}

button
{
  overflow: visible;
  
}


::placeholder
{
  color:black;
  opacity: 0.6;
}


.contactForm__inputs > input {
  margin: 10px;
}

.contact-form .btn input {
  margin-top: 10px;
}


@media (max-width: 425px) {
  .contactForm__inputs {
   
    flex-direction: column;
  }

  


  
}
