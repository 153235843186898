.resume_text
{
    text-align: center;
}

.button_texts
{
  width: 30%;
  display: grid;
  margin:auto;
  grid-template-columns: 1fr 1fr;
}

.left{
  width: 50%;
  float: left;
  border-right-style: solid;
  min-height: 600px;
}

.right{
  width: 50%;
  float: right;
  min-height: 50px;
}

.verticleCenter{
  text-align: center;
}

.verticleCenter:hover{
  text-align: center;
  cursor: pointer;
  opacity: 0.5;
}

.image{
  height: 200px;
  width: 200px;
  margin-top: 20%;
}

.image2{
  height: 250px;
  width: 250px;
  margin-top: 15%;
}

.text{
  margin-top: 30px;
}

.text2{
  margin-top: 60px;
}

.mainText{
  text-align: center;
}
